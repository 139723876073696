<template>
  <http-put-button
    v-bind="$props"
    v-on="$listeners"
    :href="`/api/webhooks/${(value || {}).id}`"
    :value="value"
    version="v2"
    @http4xx="onError"
  >
  </http-put-button>
</template>

<i18n>
{
    "en": {
        "editWebhookButton.errorMessage": "There was an error encountered while editing this webhook. If everything looks right please contact support so we can help you :)"
    }
}
</i18n>

<script>
import { toastError } from "@/components/Common/Toast.vue";
import HttpPutButton from "@/components/Common/HttpPutButton.vue";
export default {
  props: { ...HttpPutButton.props },
  methods: {
    onError() {
      toastError(this.$t('editWebhookButton.errorMessage'));
    },
  },
};
</script>