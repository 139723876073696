
import { IGptChatOverviewResult, ISpendUsageTableEntityViewModel, ISpendUsageTableItemViewModel } from '@/models';
import { PluralEntityType, fromPlural } from '@/models/EntityType';
import { AgGridVue, RowGroupingModule, ColDef } from '@/plugins/agGrid';
import { defineComponent } from 'vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import FeatureNames from '@/lib/FeatureNames';
import ApiV2 from '@/lib/ApiV2';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';
import { ISpendUsageTableSlotViewModel, SpendUsageTableConstants } from '@/models/ISpendUsageTable';

const SPAN_WITH_NO_VALUE = '<span>-</span>';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
const slotHeader = (header: string, fieldName: string) => {
    return {
        headerName: header,
        field: fieldName,
        sortable: true,
        filter: true,
        editable: true,
        rowGroup: false,
        resizable: true,
        enableRowGroup: false,
        type: 'rightAligned',
        cellRenderer: (params) => {
            return `${params.value?.toFixed(3) ?? '-'}`;
        },
    };
};
export default defineComponent({
    components: { AgGridVue },
    props: {
        items: Object,
        loading: Boolean,
        currencyCode: String,
        segregateBy: String,
        granularity: String,
        nameHeaderTitle: String,
        tagKey: String,
        cardTitleText: String,
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        disableLinks: {
            type: Boolean,
            default: false,
        },
        hideDefaultFooter: Boolean,
        hideExport: Boolean,
        hideIntelligence: Boolean,
        prefix: {
            type: String,
            default: '/explore',
        },
        unitOfDisplay: String,
        domLayout: {
            type: String,
            default: 'autoHeight',
        },
    },
    data() {
        return {
            sortBy: 'total',
            sortDesc: true,
            modules: [RowGroupingModule],
            showGptCreate: false,
            loadingGptCreate: false,
            entityId: '',
            entityType: '',
            gridApi: null,
            gridColumnApi: null,
            displayOptions: UnitOfDisplay,
        };
    },
    methods: {
        exportCsv() {
            if (this.gridApi) {
                this.gridApi.exportDataAsCsv();
            }
        },
        async go() {
            this.loadingGptCreate = true;
            const response = await ApiV2.http
                .post<IGptChatOverviewResult>(`/api/gpt`, {
                    entityId: this.entityId,
                    entityType: this.entityType,
                } as IGptChatOverviewResult)
                .then((r) => r.data);

            if (this.showGptCreate) {
                if (!this.noNav) this.$router.push(`/cloud-intelligence/?chatId=${response.chatId}`);
                this.$emit('created', response);
            }
        },
        close() {
            this.loadingGptCreate = false;
            this.showGptCreate = false;
        },
        sizeToFit() {
            this.gridApi.sizeColumnsToFit();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.$nextTick(() => {
                if (this.gridColumnApi) {
                    const allColumnIds = [];
                    this.gridColumnApi.getAllColumns().forEach((column) => {
                        allColumnIds.push(column.getColId());
                    });
                    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
                }
            });
            this.sizeToFit();
        },
    },
    computed: {
        ...mapStores(useTenantStore),
        headers() {
            const currencyCode = this.currencyCode;
            const segregateByList = this.segregateBy.split(',');
            const showDataAsMonetaryCost = this.unitOfDisplay != UnitOfDisplay.consumption;

            const headers: ColDef[] = segregateByList.map((segregateBy: string) => ({
                headerName: capitalizeFirstLetter(segregateBy),
                field: `e_${segregateBy}`,
                sortable: true,
                filter: true,
                editable: true,
                rowGroup: false,
                enableRowGroup: true,
                pinned: 'left',
                resizable: true,
                valueFormatter: (val: any): any => val.value,
                cellRenderer: (params: any) => {
                    if (!params.data) {
                        return params.value;
                    }
                    const record: ISpendUsageTableItemViewModel = params.data;
                    const entity: ISpendUsageTableEntityViewModel = record.entities.find((e) => e.type === segregateBy);
                    if (!entity) return SPAN_WITH_NO_VALUE;
                    if (record.isTotal) {
                        return `<span>Total</span>`;
                    }
                    if (
                        record.isOther ||
                        (this.items && this.items.noFollow) ||
                        this.disableLinks ||
                        segregateBy === PluralEntityType.meters
                    ) {
                        return `<span>${entity.name}</span>`;
                    }
                    let route: string;
                    let name: string;
                    if (segregateBy === PluralEntityType.tags) {
                        const key = encodeURIComponent(this.tagKey);
                        let id = encodeURIComponent(entity.id);
                        if (entity.id == this.tagKey && entity.id) {
                            id = encodeURIComponent(`${entity.id}:(Blanks)`);
                        }
                        route = `${this.prefix}/tagkeys/${key}/tag/${id && key != id ? id : '(Blanks)'}`;
                        name = entity.id ? entity.id : '<Untagged>';
                    } else if (segregateBy.startsWith('tags/')) {
                        const [key, val] = entity.id.split(':');
                        route = `${this.prefix}/tagkeys/${key}/tag/${val ? val : '(Blanks)'}`;
                        name = val ? val : '<Untagged>';
                    } else {
                        route = `${this.prefix}/${entity.type}/${entity.id}`;
                        name = entity.name;
                    }
                    const link = document.createElement('a');
                    link.href = this.$router.resolve(route).href;
                    link.innerText = name;
                    link.addEventListener('click', (e) => {
                        e.preventDefault();
                        this.$router.push(route);
                    });
                    return link;
                },
            }));

            // If the user has toggled consumption, add units to the pinned columns
            if (this.segregateBy === PluralEntityType.meters && !showDataAsMonetaryCost) {
                headers.push({
                    headerName: 'Units',
                    field: 'unit',
                    pinned: 'left',
                    resizable: true,
                    enableRowGroup: true,
                    cellRenderer: (params) => {
                        return `<b>${params.value?.toFixed(3) ?? '-'}</b>`;
                    },
                });
            }

            if (this.canShowIntelligence) {
                headers.push({
                    headerName: 'Intelligence',
                    field: 'intelligence',
                    pinned: 'left',
                    cellRenderer: (params) => {
                        const record: ISpendUsageTableItemViewModel | null = params.data;
                        if (!record || record.isTotal || record.isOther) return SPAN_WITH_NO_VALUE;

                        const entity = record.entities[0];
                        const link = document.createElement('a');
                        const icon = document.createElement('i');
                        icon.classList.add(...'v-icon notranslate flip-x material-icons'.split(' '));
                        icon.innerText = 'psychology';
                        link.appendChild(icon);

                        link.addEventListener('click', (e) => {
                            e.preventDefault();
                            this.entityId = entity.id;
                            this.entityType = fromPlural(entity.type as PluralEntityType);
                            this.showGptCreate = true;
                        });
                        return link;
                    },
                });
            }

            headers.push({
                headerName: 'CostView',
                field: 'costView',
                sortable: true,
                sort: null,
                filter: true,
                editable: true,
                rowGroup: false,
                enableRowGroup: true,
                resizable: true,
                type: 'rightAligned',
                cellRenderer(params: any) {
                    switch (params.value) {
                        case 'Actual':
                            return 'Actual';
                        case 'Amortized':
                            return 'Amortized';
                        case 'Utilized':
                            return 'Utilized';
                        case null:
                            return 'Usage';
                        default:
                            return params.value;
                    }
                },
            });

            headers.push({
                headerName: `Total (${currencyCode})`,
                field: showDataAsMonetaryCost ? 'total' : 'totalConsumption',
                sortable: true,
                sort: 'desc',
                filter: true,
                editable: true,
                rowGroup: false,
                enableRowGroup: false,
                resizable: true,
                type: 'rightAligned',
                cellRenderer(params: any) {
                    return `<b>${params.value?.toFixed(3) ?? '-'}</b>`;
                },
            });

            const monthSlots = this.items?.monthSlots || [];
            for (const slot of monthSlots) {
                const field = showDataAsMonetaryCost
                    ? slot.itemValuePropName
                    : `${slot.costValuePropName}${SpendUsageTableConstants.consumptionKeySuffix}`;
                headers.push(slotHeader(slot.header, field));
            }

            const dailySlots: ISpendUsageTableSlotViewModel[] = this.items?.dailySlots || [];
            for (const slot of dailySlots) {
                const field = showDataAsMonetaryCost ? slot.costValuePropName : slot.unitValuePropName;
                headers.push(slotHeader(slot.header, field));
            }
            return headers;
        },
        canShowIntelligence() {
            const segregateByList = this.segregateBy.split(',');
            return (
                segregateByList.length === 1 &&
                this.tenantStore.hasTenantFeature(FeatureNames.enableCloudIntelligence) &&
                (this.segregateBy === PluralEntityType.products || this.segregateBy === PluralEntityType.services) &&
                !this.hideIntelligence
            );
        },
        records(): ISpendUsageTableItemViewModel {
            return (this.items?.records || []).filter((r: ISpendUsageTableItemViewModel) => !r.isOther);
        },
        monthSlots() {
            return this.items?.monthSlots || [];
        },
        pinnedTopRowData() {
            return [];
        },
        pinnedBottomRowData() {
            const segregateByList = this.segregateBy?.split(',') || [];
            if (!segregateByList.length) return [];

            const spendUsageItems: ISpendUsageTableItemViewModel[] = this.items?.records || [];
            const summaryRow: ISpendUsageTableItemViewModel = {
                entities: [{ id: '', name: 'Total Spend', type: segregateByList[0] }],
                total: 0,
                isOther: false,
                isTotal: true,
                totalConsumption: 0,
                unit: '',
                timestamp: new Date().getTime(),
            };
            for (const usageItem of spendUsageItems) {
                summaryRow.total += usageItem.total;
                summaryRow.totalConsumption += usageItem.totalConsumption;
                for (const slot of this.monthSlots) {
                    if (!summaryRow[slot.itemValuePropName]) {
                        summaryRow[slot.itemValuePropName] = 0;
                    }
                    summaryRow[slot.itemValuePropName] += usageItem[slot.itemValuePropName] || 0;
                }
            }
            return [...spendUsageItems.filter((r) => r.isOther), summaryRow];
        },
    },
});
