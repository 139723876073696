
import VueGridLayout from 'vue-grid-layout';
import { defineComponent, ref, watch } from 'vue';
import { storeToRefs } from '@/stores';
import { useGridStore } from '@/stores/grid';
import GridWidget from './Widgets/GridWidget.vue';
import { CCWidget } from '@/merge/Grid';

export type IGridLayoutItem = CCWidget & { i: string; }

export default defineComponent({
    props: { gridId: String },
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        GridWidget: GridWidget,
    },
    setup(_, { emit }) {
        const gridStore = useGridStore();
        const { gridDoc, cachedPositionalUpdates, cachedSizingUpdates } = storeToRefs(gridStore);
        const layout = ref<IGridLayoutItem[]>([]);

        watch(
            () => gridDoc.value,
            (d) => {
                if (!d?.elements) return;
                const elements = Object.entries(d.elements);
                if (!elements.length) {
                    layout.value = [];
                } else {
                    layout.value = elements.map(([k, v]) => {
                        const pos = cachedPositionalUpdates.value[k] || { x: v.x, y: v.y };
                        const rect = cachedSizingUpdates.value[k] || { w: v.w, h: v.h };
                        return {
                            ...pos,
                            ...rect,
                            id: v.id,
                            i: v.id,
                            name: v.name,
                            type: v.type,
                            config: v.config,
                        } as IGridLayoutItem;
                    });
                }
            },
            { immediate: true }
        );
        const handleMoved = (i: string, newX: number, newY: number) => {
            cachedPositionalUpdates.value[i] = { x: newX, y: newY };
        };
        const handleResized = (i: string, newX: number, newY: number) => {
            cachedSizingUpdates.value[i] = { w: newY, h: newX };
        };
        const handleEditClicked = (item: IGridLayoutItem) => {
            emit('onEdit', item);
        };

        const handleDeleteClicked = (item: IGridLayoutItem) => {
            emit('onDelete', item);
        };
        return {
            layout,
            handleMoved,
            handleResized,
            gridDoc,
            cachedPositionalUpdates,
            cachedSizingUpdates,
            onDeleteWidget: handleDeleteClicked,
            onEditWidget: handleEditClicked,
        };
    },
});
