<template>
    <v-banner
      v-bind="$attrs"          
      v-on="$listeners"        
      :color="color"
      :icon="icon"
      :single-line="singleLine"
      :sticky="sticky"
      :value="value"
    >
        <v-banner-text>
            <slot name="text"></slot>
        </v-banner-text>

        <template v-slot:actions>
            <slot name="actions"></slot> 
        </template>
        
        <slot></slot> 
    </v-banner>
  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: ''
      },
      singleLine: {
        type: Boolean,
        default: false
      },
      sticky: {
        type: Boolean,
        default: false
      },
      value: {
        type: Boolean,
        default: true
      }
    }
  };
  </script>
  