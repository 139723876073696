enum CloudAccountType {
    azureEa = 'AzureEa',
    amazon = 'Amazon',
    amazonPax8 = 'AmazonPax8',
    azurePax8 = 'AzurePax8',
    oracle = 'Oracle',
    azure = 'Azure',
    google = 'Google',
    microsoftCsp = 'MicrosoftCsp',
    softlayer = 'SoftLayer',
    resellerCustomer = 'ResellerCustomer',
    rhipe = 'Rhipe',
    selfReferential = 'SelfReferential',
    vmWare = 'VmWare',
    alibaba = 'Alibaba',
    custom = 'Custom',
    snowflake = 'SnowFlake',
    azurecme = 'AzureCme'
}

export default CloudAccountType;