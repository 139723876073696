
import { defineComponent, ref, watch, computed } from 'vue';
import {
    calculateOptionsDescription,
    convertSpendOptionsToQuery,
} from '../../Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import SpendUsageBarChart, {
    convertUsageResponseToDatasets,
    convertEventsToAnnotations,
    UsageReportTypes,
    convertAnnotatesToAnnotations,
} from '../../Domain.Usage/SpendUsageBarChart.vue';
import CarbonBarChart, { convertUsageResponseToEmissionsDatasets } from '../../Domain.Carbon/CarbonBarChart.vue';
import hash from 'object-hash';
import { useFilterStore } from '@/stores';
import ApiV2 from '@/lib/ApiV2';
import { getSpend } from '@/lib/Api';
import { ChartDataset, Point } from 'chart.js';
import { generateSpendUsageOptionsFromReport, Granularity, PluralEntityType, SpendFieldNames } from '@/models';
import moment from '@/lib/moment';
import { Annotation } from '@/models/Annotation';
import AnnotationsTimeline from '../../Domain.Annotations/AnnotationsTimeline.vue';
import { ISpendUsageWidgetData } from '@/merge/Grid';

export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    components: { SpendUsageBarChart, CarbonBarChart, AnnotationsTimeline },
    data() {
        return {
            selectedAnnotations: [],
            annotationDialog: false,
        };
    },
    setup(props, context) {
        const filterStore = useFilterStore();

        const usageOptions = ref({
            ...filterStore.options,
        });

        const focusedSegregation = ref<number>(0);
        const segregations = ref<string[]>([]);
        const dataset = ref('Usage');
        const costViews = ref(['Actual', 'Amortized', 'Utilized']);
        const includeAnnotations = ref(false);

        const handleDatasetClicked = () => {
            //emit('onEdit');
        };

        const newChartData = ref({
            labels: [] as string[],
            datasets: [] as ChartDataset<'bar' | 'line', Point[]>[],
        });
        const maxDate = ref<string | null>(undefined);

        const lastHash = ref<string | null>(null);
        const annotations = ref<any>([]);
        const createdAnnotations = ref();
        const isLoadingUsage = ref(false);

        const loadUsage = async (
            toTrends: (searchParams: URLSearchParams) => void,
            onAnnotationSelection: (annotations: Annotation[], xMin: number, xMax: number) => void
        ) => {
            if (isLoadingUsage.value) return;
            const optionsHash = hash([usageOptions.value, dataset.value, costViews.value, includeAnnotations.value]);
            if (lastHash.value === optionsHash) return;
            lastHash.value = optionsHash;

            try {
                isLoadingUsage.value = true;
                const params = convertSpendOptionsToQuery(usageOptions.value);
                if (!params) return;
                const spendParams = { ...params, segregateBy: usageOptions.value.segregateBy };

                const fields =
                    dataset.value === 'Usage' ? [SpendFieldNames.Charges] : [SpendFieldNames.CO2e, SpendFieldNames.kWh];

                const results = await Promise.all(
                    costViews.value.map((cv) => getSpend({ ...spendParams, costView: cv, fields }))
                );

                let chartLabels = [];
                let chartDatasets = [];

                maxDate.value = params.toDate;

                if (dataset.value === 'Usage') {
                    const datasets = results.map((r, i) => {
                        const costView = costViews.value[i];
                        const labelSuffix = costView === 'Actual' ? 'Actual' : 'Utilized';
                        const stackGroup = costView.toLowerCase();
                        return convertUsageResponseToDatasets(r, {
                            labelSuffix,
                            stackGroup,
                            reportType: UsageReportTypes.charges,
                        });
                    });
                    chartLabels = datasets.map((d) => d.labels).reduce((a, b) => [...a, ...b], []);
                    chartDatasets = datasets.map((d) => d.datasets).reduce((a, b) => [...a, ...b], []);
                } else {
                    const datasets = results.map((r, i) => {
                        const costView = costViews.value[i];
                        const labelSuffix = costView === 'Actual' ? 'Actual' : 'Utilized';
                        const stackGroup = costView.toLowerCase();
                        return convertUsageResponseToEmissionsDatasets(r, {
                            labelSuffix,
                            stackGroup,
                        });
                    });
                    chartLabels = datasets.map((d) => d[0].labels).reduce((a, b) => [...a, ...b], []);
                    chartDatasets = datasets.map((d) => d[0].datasets).reduce((a, b) => [...a, ...b], []);
                }

                newChartData.value = {
                    labels: chartLabels,
                    datasets: chartDatasets,
                } as any;

                if (includeAnnotations.value) {
                    createdAnnotations.value = await ApiV2.http
                        .get(`/api/annotations`, {
                            params: {
                                fromDate: params.fromDate,
                                toDate: params.toDate,
                                cloudAccountIds: params.cloudAccountIds,
                                subscriptionIds: params.subscriptionIds,
                                serviceIds: params.serviceIds,
                                tagKeyValue: params.tagKeyValue,
                            },
                        })
                        .then((r) => r.data);

                    const anno = convertAnnotatesToAnnotations(
                        createdAnnotations.value,
                        chartDatasets,
                        usageOptions.value.granularity as Granularity,
                        (xMin, xMax) => {
                            onAnnotationSelection(createdAnnotations.value, xMin, xMax);
                        }
                    );

                    const spikeEventsResponse = await ApiV2.http
                        .get(`/api/events?eventType=SpikeEventAlert`, { params })
                        .catch((err) => {
                            if (err.response.status === 400) return { data: {} };
                            throw err;
                        })
                        .then((r) => r.data);

                    const spikes = convertEventsToAnnotations(spikeEventsResponse, (xMin, xMax) => {
                        const toDate = new Date(xMax);
                        const numDaysBetween = Math.ceil((xMax - xMin) / (1000 * 3600 * 24));
                        const period = `${numDaysBetween}d`;
                        const segregateBy = usageOptions.value.segregateBy === 'services' ? 'services' : 'products';
                        const searchParams = new URLSearchParams({
                            toDate: toDate.toISOString(),
                            period,
                            segregateBy,
                        });
                        toTrends(searchParams);
                    });

                    annotations.value = [...anno, ...spikes];
                } else {
                    createdAnnotations.value = [];
                    annotations.value = [];
                }
            } finally {
                isLoadingUsage.value = false;
            }
        };

        watch(
            () => props.config,
            (opt) => {
                if (!opt) return;
                const report = opt as ISpendUsageWidgetData;
                segregations.value = (report.segregateBy || '').split(',').filter((a) => a);
                const params = generateSpendUsageOptionsFromReport(report);
                const segregateBy = segregations.value[focusedSegregation.value] as PluralEntityType;
                usageOptions.value = { ...params, segregateBy };
                dataset.value = report.dataset || 'Usage';
                costViews.value = report.costViews || ['Actual'];
                includeAnnotations.value = report.includeAnnotations || false;
            },
            { immediate: true, deep: true }
        );

        watch(
            () => focusedSegregation.value,
            (v) => {
                const segregateBy = segregations.value[v] as PluralEntityType;
                if (usageOptions.value.segregateBy !== segregateBy) {
                    usageOptions.value = { ...usageOptions.value, segregateBy };
                }
            },
            { immediate: false }
        );
        const loadTriggers = computed(() => {
            return [usageOptions.value, dataset.value, costViews.value];
        });

        return {
            usageOptions,
            handleDatasetClicked,
            annotations,
            newChartData,
            loadUsage,
            isLoadingUsage,
            focusedSegregation,
            segregations,
            lastHash,
            loadTriggers,
            dataset,
            createdAnnotations,
            maxDate,
        };
    },
    methods: {
        toTrends(searchParams: URLSearchParams) {
            this.$router.push(`/trends?${searchParams.toString()}`);
        },
        onRangeSelection(event: { xMax: number; xMin: number }) {
            const toDate = new Date(event.xMax);
            toDate.setHours(0, 0, 0, 0);

            const numDaysBetween = Math.ceil((event.xMax - event.xMin) / (1000 * 3600 * 24));
            const period = `${numDaysBetween}d`;
            const segregateBy = this.usageOptions.value.segregateBy === 'services' ? 'services' : 'products';
            const searchParams = new URLSearchParams({
                toDate: toDate.toISOString(),
                period,
                segregateBy,
            });
            this.toTrends(searchParams);
        },
        onAnnotationSelection(annotations: Annotation[], xMin: number, xMax: number) {
            const toDate = new Date(xMax).getTime();
            const fromDate = new Date(xMin).getTime();
            this.selectedAnnotations = annotations.filter((x) => {
                const date = new Date(x.annotationDate).getTime();
                return date >= fromDate && date <= toDate;
            });
            this.annotationDialog = true;
        },
    },
    watch: {
        loadTriggers: {
            handler() {
                this.loadUsage(this.toTrends, this.onAnnotationSelection);
            },
            immediate: true,
        },
    },
    computed: {
        chartTitle() {
            return this.name || calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
        },
        annotationDialogSubtitle() {
            if (this.selectedAnnotations && this.selectedAnnotations.length > 0) {
                const dates = this.selectedAnnotations
                    .map((x) => {
                        const date = new Date(x.annotationDate);
                        date.setHours(0, 0, 0, 0);
                        return date;
                    })
                    .sort((a, b) => a.getTime() - b.getTime());

                const start = moment(dates[0]);
                const end = moment(dates[dates.length - 1]);
                if (start.isSame(end)) {
                    return start.format('MMMM DD');
                }
                return `${start.format('MMMM DD')} - ${end.format('MMMM DD')}`;
            }
            return undefined;
        },
    },
});
