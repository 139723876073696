
import { defineComponent } from 'vue';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import SetUserGroupCard from '@/components/Settings.Users/SetUserGroupCard.vue';
import DeleteEntityDialog from '@/components/Common/DeleteEntityDialog.vue';
import ModalListInput from '@/components/Common/ModalListInput.vue';

export default defineComponent({
    title() {
        return 'Groups';
    },
    components: {
        SetUserGroupCard,
        DeleteEntityDialog,
        ModalListInput
    },
    data() {
        return {
            userGroups: [],
            newUserGroup: { name: '', description: '' },
            focusGroup: { id: '', name: '', description: '' },
            isCreatingGroup: false,
            isEditingGroup: false,
            isDeletingGroup: false,
        };
    },
    methods: {
        getUserGroups: waitFor('getUserGroups', async function () {
            this.userGroups = await ApiV2.http.get('api/metadata/tenant/current/usergroups').then((r) => r.data);
        }),
        createUserGroup: waitFor('creatingUserGroup', async function (form: { name: string; description: string }) {
            await ApiV2.http.post('api/metadata/tenant/current/usergroups', form);
            this.getUserGroups();
        }),
        editUserGroup: waitFor(
            'editingUserGroup',
            async function (id: string, form: { name: string; description: string }) {
                await ApiV2.http.put(`api/metadata/tenant/current/usergroups/${id}`, form);
                this.getUserGroups();
            }
        ),
        startEditUserGroup(userGroup: any) {
            this.isEditingGroup = true;
            this.focusGroup = userGroup;
        },
        startDeleteUserGroup(userGroup: any) {
            this.isDeletingGroup = true;
            this.focusGroup = userGroup;
        },
        async updateGroupMembers(userGroup:any, emails:string[]) {
            await ApiV2.http.put(`api/metadata/tenant/current/usergroups/${userGroup.id}/users`, emails);
            this.getUserGroups();
        }
    },
    created() {
        this.getUserGroups();
    },
});
