
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import createUsagePieChart, { IClickEvent, convertUsageToPieDataset } from '@/chartjs/createPieChart';
  import { useTenantStore, storeToRefs } from '@/stores';
  
  export { IClickEvent, convertUsageToPieDataset };
  
  export default defineComponent({
    props: {
      data: Object,
      height: String,
    },
    emits: ['click'],
    setup(p, { emit }) {
      const root = ref<HTMLDivElement | null>(null);
      const canvas = ref<HTMLCanvasElement | null>(null);
      const { currencyCode } = storeToRefs(useTenantStore());
  
      const renderChart = () => {
        if (!p.data || !root.value) return;
  
        root.value.innerHTML = '';
        canvas.value = document.createElement('canvas');
        canvas.value.classList.add('chart-canvas');
  
        if (p.height === '100%') {
          canvas.value.classList.add('fill-height-srsly');
          root.value.classList.add('fill-height');
        } else {
          canvas.value.style.height = p.height || '400px';
        }
  
        root.value.appendChild(canvas.value);
  
        const chart = createUsagePieChart(canvas.value, {
          currencyCode: currencyCode.value,
          onClick(event: IClickEvent) {
            emit('click', event);
          }
        });
  
        chart.data = p.data as any;
        chart.update();
      };
  
      onMounted(() => renderChart());
  
      watch(
        () => p.data,
        () => renderChart(),
        { immediate: true, deep: true }
      );
  
      return { root, canvas };
    },
  });
  