import { PublicClientApplication, LogLevel, InteractionRequiredAuthError } from '@azure/msal-browser';
import Config from '@/lib/Config';

const config = Config.get();

const scopes = ['user.read'];

const loginRequest = { scopes };

const msalConfig = {
    auth: {
        clientId: config.MSAL_CLIENTID,
        authority: config.MSAL_AUTHORITY,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

const instance = new PublicClientApplication(msalConfig);

const initialize = async ({ onRedirectCallback }: { onRedirectCallback: (returnTo: string) => void }) => {
    await instance.initialize();

    await instance.handleRedirectPromise();
    await loginWithRedirect({});

    onRedirectCallback(null)
}

const loginWithRedirect = async (options: any) => {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
        instance.loginRedirect({ ...loginRequest, ...options });
    } else {
        instance.setActiveAccount(accounts[0]);
    }
}

const getTokenSilently = async () => {
    try {
        const response = await instance.acquireTokenSilent(loginRequest);
        return response.idToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect(loginRequest);
        }
    }
}

const isAuthenticated = async () => {
    return instance.getActiveAccount() != null;
}

const logoutWithRedirect = async () => {
    const account = instance.getActiveAccount();
    if (account) {
        await instance.logoutRedirect({ account, postLogoutRedirectUri: window.location.origin });
    } else {
        console.warn('No active account found. Cannot log out.');
    }
}

const getUser = async () => {
    const account = instance.getActiveAccount();
    if (account) {
        return { 
            name: account.name,
            email: account.username,
        };
    }
}

export default {
    instance,
    initialize,
    isAuthenticated,
    getTokenSilently,
    getUser,
    loginWithRedirect,
    logoutWithRedirect
};
