
import { defineComponent } from 'vue';
import { waitFor } from '@/plugins/vue-wait';
import ApiV2 from '@/lib/ApiV2';
import { User } from '@auth0/auth0-spa-js';

export default defineComponent({
    title() {
        return 'Audit';
    },
    components: {
    },
    data() {
        return {
            users: [] as User[],
            headers: [
                {
                    text: this.$t("startdate"),
                    value: 'startDate',
                    sortable: true,
                },
                {
                    text: this.$t("eventtype"),
                    value: 'eventType'
                },
                {
                    text: this.$t("environment"),
                    value: 'environment'
                },
                {
                    text: this.$t("duration"),
                    value: 'duration',
                    sortable: false
                },
                {
                    text: this.$t("status"),
                    value: 'responseStatus'
                },
                {
                    text: '',
                    value: 'data-table-expand'
                }
            ],
            auditLog: [],
            filterUserEmail: null,
            loading: false,
            singleExpand: false
        };
    },
    methods: {
        getAuditing: waitFor('getAuditing', async function () {
            console.log(this.filterUserEmail);
            this.loading = true;

            try {
                const logs = await ApiV2.http.get(`/api/auditing?email=${this.filterUserEmail}`).then((r) => r.data);
                this.auditLog = logs;
            } finally {
                this.loading = false;
            }
        }),
        getUsers: waitFor('getUsers', async function () {
            const users = await ApiV2.http.get(`api/metadata/tenants/current/allusers`).then((r) => r.data);

            this.users = users;
        }),
    },
    created() {
        this.getUsers();
    },
});

