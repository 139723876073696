<template>
    <form-card :title="$t('setAzureCmeCredentialsCard.title')" :loading="loading" v-model="valid">
        <template #subtitle>Configure your Azure Resource Manager credentials. These credentials correspond to a single
            Azure AD app
            that you need to configure in your tenant. You also need to configure the options for the Azure Cost
            Management export. More information is available <a target="blank" :href="`${docsUrl}/Setup/AzureCME`">here
            </a>.
        </template>
        <v-text-field v-model="name" :disabled="disabled && disabled.Name" :rules="nameRules" required autofocus
            :label="$t('name')"></v-text-field>
        <v-row>
            <v-col>
                <v-checkbox v-model="useTenantCurrencies" :label="$t('useTenantCurrencies')" style="width: 300px"
                    :disabled="disabled && disabled.UseTenantCurrencies">
                    <template #append>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon small v-on="on" v-bind="attrs">
                                    <v-icon>info</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('useTenantCurrencies.tooltip') }}</span>
                        </v-tooltip>
                    </template></v-checkbox>
            </v-col>
            <v-col>
                <v-checkbox v-model="copyRemoteTags" :label="$t('setAzureCmeCredentialsCard.copyRemoteTags')"
                    style="width: 300px" :disabled="disabled && disabled.CopyRemoteTags">
                    <template #append>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon small v-on="on" v-bind="attrs">
                                    <v-icon>info</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('setAzureCmeCredentialsCard.copyRemoteTags.tooltip') }}</span>
                        </v-tooltip>
                    </template></v-checkbox>
            </v-col>
        </v-row>
        <v-text-field v-model.trim="tenantId" :rules="tenantIdRules" required
            :label="$t('setAzureCmeCredentialsCard.tenantId')"></v-text-field>
        <v-text-field v-model.trim="clientId" :rules="clientIdRules" :counter="36" required
            :label="$t('setAzureCmeCredentialsCard.clientId')"></v-text-field>
        <v-text-field v-model.trim="clientSecret" :rules="clientSecretRules"
            :append-icon="showClientSecret ? 'visibility_off' : 'visibility'"
            @click:append="() => (showClientSecret = !showClientSecret)" :type="showClientSecret ? 'text' : 'password'"
            required :label="$t('setAzureCmeCredentialsCard.clientSecret')"></v-text-field>
        <v-row>
            <v-col>
                <v-text-field v-model.trim="storageAccount" :counter="24" :rules="storageAccountRules" required
                    :label="$t('setAzureCmeCredentialsCard.storageAccount')"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-model.trim="container" :counter="36" :rules="containerRules" required
                    :label="$t('setAzureCmeCredentialsCard.container')"></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field v-model.trim="directoryName" :counter="36" :rules="directoryNameRules" required
                    :label="$t('setAzureCmeCredentialsCard.directoryName')"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field v-model.trim="exportName" :counter="36" :rules="exportNameRules" required
                    :label="$t('setAzureCmeCredentialsCard.exportName')"></v-text-field>
            </v-col>
        </v-row>
        <template #actions="{ formId, reset }">
            <slot name="actions" :form="{
                Name: name,
                UseTenantCurrencies: useTenantCurrencies,
                Features: copyRemoteTags ? 'CopyRemoteTags' : null,
                AzureEntraApplicationWithStorageCredential: {
                    ClientDomain: tenantId,
                    ProviderId: clientId,
                    Token: clientSecret,
                    RegionCode: storageAccount,
                    Container: container.concat('/', directoryName, '/', exportName)
                },
            }" :formId="formId" :isValid="valid" :loading="loading" :clear="reset"></slot>
        </template>
    </form-card>
</template>

<i18n>
{
    "en": {
        "setAzureCmeCredentialsCard.title": "Set Azure Credentials (Cost Management Export)", 
        "setAzureCmeCredentialsCard.name.required": "A name for this cloud connection is required",
        "setAzureCmeCredentialsCard.tenantId": "Tenant ID",
        "setAzureCmeCredentialsCard.tenantId.required": "Tenant ID is required",
        "setAzureCmeCredentialsCard.tenantId.notGuid": "Tenant ID is not a valid GUID",
        "setAzureCmeCredentialsCard.copyRemoteTags": "Load Current Azure Tags",
        "setAzureCmeCredentialsCard.copyRemoteTags.tooltip": "Do you want to load the current subscription and resource group tags?",
        "setAzureCmeCredentialsCard.clientId": "Client ID (App ID)",
        "setAzureCmeCredentialsCard.storageAccount": "Storage Account Name",
        "setAzureCmeCredentialsCard.storageAccount.required": "Storage Account Name is required",        
        "setAzureCmeCredentialsCard.container": "Container Name",
        "setAzureCmeCredentialsCard.container.required": "Container Name is required",
        "setAzureCmeCredentialsCard.directoryName": "Directory Name",
        "setAzureCmeCredentialsCard.directoryName.required": "Directory is required",
        "setAzureCmeCredentialsCard.exportName": "Export Name",
        "setAzureCmeCredentialsCard.exportName.required": "Export Name is required",
        "setAzureCmeCredentialsCard.clientId.required": "Client ID is required",
        "setAzureCmeCredentialsCard.clientId.notGuid": "Client ID is not a valid GUID",
        "setAzureCmeCredentialsCard.clientSecret": "Client Secret",
        "setAzureCmeCredentialsCard.clientSecret.required": "Client Secret is required"
    }
}
</i18n>

<script>
import Config from '@/lib/Config';
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export default {
    props: ['loading', 'value', 'disabled'],
    data() {
        return {
            docsUrl: Config.get().DOCS_URL,
            valid: false,
            name: '',
            nameRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.name.required')],
            storageAccount: '',
            storageAccountRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.storageAccount.required')],
            container: '',
            containerRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.container.required')],
            directoryName: '',
            directoryNameRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.directoryName.required')],
            exportName: '',
            exportNameRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.exportName.required')],
            useTenantCurrencies: false,
            copyRemoteTags: false,
            tenantId: '',
            tenantIdRules: [
                (v) => !!v || this.$t('setAzureCmeCredentialsCard.tenantId.required'),
                (v) => guidRegex.test(v) || this.$t('setAzureCmeCredentialsCard.tenantId.notGuid'),
            ],
            clientId: '',
            clientIdRules: [
                (v) => !!v || this.$t('setAzureCmeCredentialsCard.clientId.required'),
                (v) => guidRegex.test(v) || this.$t('setAzureCmeCredentialsCard.clientId.notGuid'),
            ],
            clientSecret: '',
            clientSecretRules: [(v) => !!v || this.$t('setAzureCmeCredentialsCard.clientSecret.required')],
            showClientSecret: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.name = val.name;
                this.useTenantCurrencies = val.useTenantCurrencies;
                this.copyRemoteTags = (val.features || '').split(',').includes('CopyRemoteTags');
                if (val.azureEntraApplicationWithStorageCredential) {
                    this.clientId = val.azureEntraApplicationWithStorageCredential.providerId;
                    this.clientSecret = val.azureEntraApplicationWithStorageCredential.token;
                    this.tenantId = val.azureEntraApplicationWithStorageCredential.clientDomain;
                    this.storageAccount = val.azureEntraApplicationWithStorageCredential.regionCode;
                    this.container = val.azureEntraApplicationWithStorageCredential.container.split('/')[0];
                    this.directoryName = val.azureEntraApplicationWithStorageCredential.container.split('/')[1];
                    this.exportName = val.azureEntraApplicationWithStorageCredential.container.split('/')[2];
                } else {
                    this.clientId = '';
                    this.clientSecret = '';
                    this.tenantId = '';
                    this.storageAccount = '';
                    this.container = '';
                    this.directoryName = '';
                    this.exportName = '';
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>