
import { defineComponent, ref, computed, watch } from 'vue';
import GridWidget from './Widgets/GridWidget.vue';
import DateRangeSelect from '@/components/Domain.Usage/DateRangeSelect.vue';
import TextDatePicker from '@/components/Common/TextDatePicker.vue';
import EntitySearchAutocomplete, { ISearchEntityViewModel } from '../Common/EntitySearchAutocomplete.vue';
import { Granularity, PluralEntityType, EntityType, ICustomViewResult, CloudProviderType } from '@/models';
import { useTenantStore } from '@/stores/tenant';
import {
    CCWidget,
    WidgetTypes,
    INoteWidgetData,
    IEmbedWidgetData,
    ITrendsWidgetData,
    IRecommendationsWidgetData,
    ISpendUsageWidgetData,
} from '@/merge/Grid';
import DateKey from '@/lib/DateKey';
import { getCustomViews } from '@/lib/Api';

const FIXED_FILTER_ID = '$FixedFilters';
const UNFILTERED_ID = '$AllUsage';

export default defineComponent({
    components: { GridWidget, DateRangeSelect, TextDatePicker, EntitySearchAutocomplete },
    props: { value: Boolean, config: Object },
    setup(props) {
        const tenantStore = useTenantStore();
        const widgetName = ref('');
        const widgetSubtitle = ref('');
        const widgetBodyText = ref('');
        const widgetUrl = ref('');
        const isValid = ref(false);
        const chartType = ref(WidgetTypes.columnChart);
        const trendRenderType = ref<'chart' | 'table'>('chart');
        const trendRenderTypes = ref([
            { value: 'chart', text: 'Chart' },
            { value: 'table', text: 'Table' },
        ]);
        const availableChartTypes = ref([
            { id: WidgetTypes.columnChart, name: 'Column Chart' },
            { id: WidgetTypes.lineChart, name: 'Line Chart' },
            { id: WidgetTypes.table, name: 'Table' },
            { id: WidgetTypes.pieChart, name: 'Pie Chart' },
            { id: WidgetTypes.note, name: 'Text / Note' },
            { id: WidgetTypes.embed, name: 'Embedded' },
            { id: WidgetTypes.trends, name: 'Trends' },
            { id: WidgetTypes.recommendations, name: 'Recommendations' },
        ]);
        const dateRangeKey = ref('30d');
        const topXResults = ref(10);
        const dates = ref<Date[]>([]);
        const views = ref<ICustomViewResult[]>([]);
        const searchEntities = ref<ISearchEntityViewModel[]>([]);
        const granularity = ref<Granularity>(Granularity.daily);
        const granularities = ref<Granularity[]>([Granularity.daily, Granularity.monthly]);
        const selectedFilterId = ref<string | null>(null);
        const fieldsToInclude = ref<string[]>([]);
        const tagSelections = ref<string[]>([]);
        const usePredefinedView = ref(false);
        const includeAnnotations = ref(false);
        const availableFieldsToInclude = ref([
            { name: 'Cloud Vendor', id: PluralEntityType.cloudProviders },
            { name: 'Subscription Name', id: PluralEntityType.subscriptions },
            { name: 'Product Category', id: PluralEntityType.productCategories },
            { name: 'Product Name', id: PluralEntityType.products },
            { name: 'Service', id: PluralEntityType.services },
            { name: 'Meter', id: PluralEntityType.meters },
            { name: 'Region', id: PluralEntityType.regions },
        ]);
        const availableTagsToInclude = ref<string[]>([]);
        const dataset = ref<'Emissions' | 'Usage'>('Usage');
        const availableDatasets = ref(['Usage', 'Emissions']);
        const costViewSelection = ref('Actual');
        const availableCostViews = ref([
            { name: 'SUM(Actual)', id: 'Actual' },
            { name: 'SUM(Utilization)', id: 'Utilized' },
            { name: 'SUM(Actual) & SUM(Utilization)', id: 'Actual+Utilized' },
        ]);
        const trendSegregateBy = ref<PluralEntityType.products | PluralEntityType.services>(PluralEntityType.products);
        const trendSegregations = ref([
            { value: PluralEntityType.products, text: 'Products' },
            { value: PluralEntityType.services, text: 'Services' },
            { value: PluralEntityType.subscriptions, text: 'Subscriptions' },
            { value: PluralEntityType.cloudAccounts, text: 'Cloud Connections' },
        ]);
        const supportedSearchTypes = ref([
            EntityType.customView,
            EntityType.cloudProvider,
            EntityType.productCategory,
            EntityType.geography,
            EntityType.region,
            EntityType.product,
            EntityType.cloudAccount,
            EntityType.tag,
            EntityType.subscription,
            EntityType.service,
        ]);
        const toDate = ref(new Date());

        const selectedCloudProviders = ref<string[]>([CloudProviderType.azure, CloudProviderType.amazon]);
        const availableCloudProviders = ref([
            { value: CloudProviderType.azure, text: 'Azure' },
            { value: CloudProviderType.amazon, text: 'Amazon' },
        ]);

        const availableCategories = ref([
            'Cache',
            'CDN',
            'Compute',
            'Cost',
            'Database',
            'EventHub',
            'Logic',
            'Network',
            'Performance',
            'Sql',
            'Storage',
            'Web',
        ]);
        const selectedCategories = ref(availableCategories.value);

        getCustomViews().then((v) => (views.value = v));

        watch(chartType, (t,o) => {
            if (!o) return;
            if (t === WidgetTypes.pieChart) {
                granularity.value = Granularity.monthly
            }            
        });

        const filters = computed(() => {
            return [
                { id: UNFILTERED_ID, name: 'Unfiltered / Raw' },
                { id: FIXED_FILTER_ID, name: 'Fixed Filters' },
                ...views.value.map((v) => ({ id: v.id, name: `Custom View - ${v.name}` })),
            ];
        });

        const dateRangeOptions = computed(() => {
            if (chartType.value === WidgetTypes.trends) {
                return ['1d', '2d', '3d', '5d', '7d', '14d', '30d', '1m'];
            }
            return ['30d', '60d', '90d', 'lastMonth', 'thisMonth', '6m', '13m', 'custom'];
        });

        watch(
            () => selectedFilterId.value,
            (f) => {
                usePredefinedView.value = f !== UNFILTERED_ID && f !== FIXED_FILTER_ID;
            },
            { immediate: true }
        );

        const currentWidgetConfig = computed<
            ISpendUsageWidgetData | INoteWidgetData | IEmbedWidgetData | ITrendsWidgetData | IRecommendationsWidgetData
        >(() => {
            if (chartType.value === WidgetTypes.note) {
                const textConfig: INoteWidgetData = {
                    bodyText: widgetBodyText.value,
                    title: widgetName.value,
                    subtitle: widgetSubtitle.value,
                    color: 'yellow',
                };
                return textConfig;
            }

            if (chartType.value === WidgetTypes.embed) {
                const embedConfig: IEmbedWidgetData = {
                    url: widgetUrl.value,
                    urlTitle: widgetName.value,
                };
                return embedConfig;
            }

            if (chartType.value === WidgetTypes.trends) {
                const trendConfig: ITrendsWidgetData = {
                    toDate:
                        toDate.value.toDateString() === new Date().toDateString() ? null : toDate.value.toISOString(),
                    dateRangeKey: dateRangeKey.value,
                    segregateBy: trendSegregateBy.value,
                    renderType: trendRenderType.value,
                };
                return trendConfig;
            }

            if (chartType.value === WidgetTypes.recommendations) {
                const recommendationConfig: IRecommendationsWidgetData = {
                    categories:
                        selectedCategories.value.length === availableCategories.value.length ||
                        selectedCategories.value.length === 0
                            ? null
                            : selectedCategories.value,
                    providerTypes:
                        selectedCloudProviders.value.length === availableCloudProviders.value.length ||
                        selectedCloudProviders.value.length === 0
                            ? null
                            : selectedCloudProviders.value,
                    classifications: null,
                    namespaces: null,
                    recommendationIds: null,
                };
                return recommendationConfig;
            }

            const segregateBy = (fieldsToInclude.value || [])
                .concat((tagSelections.value || []).map((t) => `tags/${t}`))
                .join(',');
            const filters = searchEntities.value || [];
            const formData: ISpendUsageWidgetData = {
                dateRangeKey: dateRangeKey.value,
                topXResults: topXResults.value,
                segregateBy,
                filters:
                    selectedFilterId.value === FIXED_FILTER_ID
                        ? filters.map((e) => ({
                              id: e.Id,
                              name: e.Name,
                              type: e.Type as EntityType,
                          }))
                        : [],
                savedViewId: usePredefinedView.value ? selectedFilterId.value : null,
                fromDate: dates.value[0]?.toISOString() || null,
                toDate: (dates.value[1] || new Date()).toISOString(),
                granularity: granularity.value,
                costViews:
                    costViewSelection.value === 'Actual+Utilized' ? ['Actual', 'Utilized'] : [costViewSelection.value],
                dataset: dataset.value,
                includeAnnotations: includeAnnotations.value,
            };
            return formData;
        });

        watch(
            () => [tenantStore.tagKey1, tenantStore.tagKey2, tenantStore.tagKey3],
            ([tagKey1, tagKey2, tagKey3]) => {
                if (availableTagsToInclude.value.length) return;
                availableTagsToInclude.value = [tagKey1, tagKey2, tagKey3].filter((t) => t);
            },
            { immediate: true }
        );

        watch(
            () => props.config,
            (cfg) => {
                if (!cfg) {
                    // reset fields
                    widgetName.value = '';
                    widgetSubtitle.value = '';
                    widgetBodyText.value = '';

                    widgetUrl.value = '';

                    dateRangeKey.value = '30d';
                    dates.value = [];
                    topXResults.value = 10;
                    searchEntities.value = [];
                    costViewSelection.value = 'Actual';
                    granularity.value = Granularity.daily;
                    usePredefinedView.value = false;
                    selectedFilterId.value = null;
                    fieldsToInclude.value = [];
                    tagSelections.value = [];
                    availableTagsToInclude.value = [];
                    dataset.value = 'Usage';
                    trendSegregateBy.value = PluralEntityType.products;

                    chartType.value = WidgetTypes.columnChart;

                    return;
                }

                const widget = cfg as CCWidget;
                if (widget.type === WidgetTypes.note) {
                    widgetName.value = widget.name;
                    widgetSubtitle.value = widget.config.subtitle || null;
                    widgetBodyText.value = widget.config.bodyText || null;
                } else if (widget.type === WidgetTypes.embed) {
                    widgetName.value = widget.name;
                    widgetUrl.value = widget.config.url || null;
                } else if (widget.type === WidgetTypes.trends) {
                    dateRangeKey.value = widget.config.dateRangeKey;
                    toDate.value = widget.config.toDate ? new Date(widget.config.toDate) : new Date();
                    trendSegregateBy.value = widget.config.segregateBy || PluralEntityType.products;
                    trendRenderType.value = widget.config.renderType || 'chart';
                } else if (widget.type === WidgetTypes.recommendations) {
                    selectedCategories.value = widget.config.categories || availableCategories.value;
                    selectedCloudProviders.value =
                        widget.config.providerTypes || availableCloudProviders.value.map((i) => i.value);
                } else {
                    widgetName.value = widget.name;
                    dateRangeKey.value = widget.config.dateRangeKey;

                    const dateKey = new DateKey(dateRangeKey.value);
                    const fromDate = widget.config.fromDate ? new Date(widget.config.fromDate) : null;
                    const toDate = widget.config.toDate ? new Date(widget.config.toDate) : null;
                    dates.value = dateKey.getEffectiveDateRange(fromDate, toDate);

                    topXResults.value = widget.config.topXResults || 10;
                    searchEntities.value = (widget.config.filters || []).map((r, i) => ({
                        Id: r.id,
                        Name: r.name,
                        Type: r.type as EntityType,
                        Description: r.name,
                        EntityIndex: i,
                        Provider: null,
                    }));
                    costViewSelection.value =
                        widget.config.costViews.length > 1 ? 'Actual+Utilized' : widget.config.costViews[0] || 'Actual';

                    granularity.value = widget.type === WidgetTypes.pieChart ? Granularity.monthly : widget.config.granularity;
                    usePredefinedView.value = !!widget.config.savedViewId;
                    if (usePredefinedView.value) {
                        selectedFilterId.value = widget.config.savedViewId || null;
                    } else if (searchEntities.value.length) {
                        selectedFilterId.value = FIXED_FILTER_ID;
                    } else {
                        selectedFilterId.value = UNFILTERED_ID;
                    }

                    const segregateByParts = widget.config.segregateBy.split(',');
                    fieldsToInclude.value = segregateByParts.filter((s) => !s.startsWith('tags/'));
                    tagSelections.value = segregateByParts
                        .filter((s) => s.startsWith('tags/'))
                        .map((s) => s.substring('tags/'.length));

                    const allTags = [...availableTagsToInclude.value, ...tagSelections.value];
                    availableTagsToInclude.value = allTags.filter((value, index) => allTags.indexOf(value) === index);
                    dataset.value = (widget.config.dataset || 'Usage') as any;
                    includeAnnotations.value = widget.config.includeAnnotations || false;
                }

                chartType.value = widget.type;
            },
            { immediate: true }
        );

        return {
            widgetName,
            widgetSubtitle,
            widgetUrl,
            widgetBodyText,
            isValid,
            dateRangeKey,
            topXResults,
            dates,
            searchEntities,
            granularity,
            granularities,
            selectedFilterId,
            fieldsToInclude,
            tagSelections,
            availableFieldsToInclude,
            availableTagsToInclude,
            dataset,
            chartType,
            usePredefinedView,
            availableChartTypes,
            costViewSelection,
            currentWidgetConfig,
            availableCostViews,
            availableDatasets,
            filters,
            views,
            supportedSearchTypes,
            trendSegregateBy,
            trendSegregations,
            toDate,
            trendRenderType,
            trendRenderTypes,
            selectedCloudProviders,
            availableCloudProviders,
            selectedCategories,
            availableCategories,
            dateRangeOptions,
            includeAnnotations,
        };
    },
});
