import * as Models from "@/models";
import ApiV2 from "./ApiV2";
import moment from "@/lib/moment";
import { appendValueToCommaString, OmitExcept } from "@/lib";
import { blobToString, stringToUint } from "./Uint";
import * as G from "../merge/Grid";

export const getCustomerUsersApi = async (
    customerId: string,
): Promise<Models.IUser[]> => {
    return ApiV2.http.get(`/api/customers/${customerId}/users`).then((r) =>
        r.data
    );
};

export const getUsage = async (
    segregateBy: Models.PluralEntityType,
    params: Models.ISpendParameters,
) => {
    return await ApiV2.http.get(`/api/spend/${segregateBy.toLowerCase()}`, {
        params,
    }).then((r) =>
        r.data as Models.ISpendResult<Models.ISpendAndConsumptionGroup>
    );
};

export const getSpend = async (
    params: Models.ISpendParameters & {
        segregateBy: string | string[];
        fields?: string | Models.SpendFieldNames[];
    },
) => {
    if (params.fields && Array.isArray(params.fields)) {
        params.fields = params.fields.join(",");
    }
    if (params.segregateBy && Array.isArray(params.segregateBy)) {
        params.segregateBy = params.segregateBy.join(",");
    }
    return await ApiV2.http.get("/api/spend", { params }).then((r) =>
        r.data as Models.ISpendResult<Models.ISpendAndConsumptionGroup>
    );
};

export const getForecast = async (
    params: Models.ISpendParameters,
    dateRangeKey: string = null,
    forecastRangeKey: string = null,
): Promise<Models.IForecastResult | null> => {
    switch (dateRangeKey) {
        case "custom":
            return null;
        case "lastMonth":
        case "thisMonth":
        case "all":
            break;
        default:
            if (dateRangeKey.endsWith("d") || dateRangeKey.endsWith("m")) {
                let forecastDuration = 0;
                let forecastUnit: "days" | "months" = "days"; // Default unit

                if (forecastRangeKey) {
                    const match = forecastRangeKey.match(/^(\d+)(d|m)$/);
                    if (match) {
                        const [, value, unit] = match;
                        forecastDuration = parseInt(value, 10);
                        forecastUnit = unit === "m" ? "months" : "days";
                    }
                }

                // Correct conversion based on granularity
                if (params.granularity === Models.Granularity.monthly) {
                    const monthsToAdd = forecastUnit === "days"
                        ? Math.ceil(forecastDuration / 30) // Convert days to months
                        : forecastDuration || 2; // Default to 2 months if unspecified
                    params = {
                        ...params,
                        toDate: moment.utc().add(monthsToAdd, "months").endOf(
                            "month",
                        ).toDate().toISOString(),
                    };
                } else if (params.granularity === Models.Granularity.daily) {
                    const daysToAdd = forecastUnit === "months"
                        ? forecastDuration * 30 // Convert months to days
                        : forecastDuration || 7; // Default to 7 days if unspecified
                    params = {
                        ...params,
                        toDate: moment.utc().add(daysToAdd, "days").toDate()
                            .toISOString(),
                    };
                }
            }
            break;
    }
    return await ApiV2.http.get(`/api/forecast`, { params })
        .catch((err) => {
            if (err.response.status !== 400) {
                console.error("Error loading forecast data", err);
            }
            return { data: {} };
        })
        .then((r) => {
            //Suggestion: return maxUsageDate as part of the results from the back-end API
            const maxUsageDate = r.data.forecasts && r.data.forecasts.length > 0
                ? r.data.forecasts.reduce((max, item) => {
                    return new Date(item.usageDate) > new Date(max.usageDate)
                        ? item
                        : max;
                }).usageDate
                : new Date().toISOString();
            const data = r.data as Models.IForecastResult;
            data.maxUsageDate = maxUsageDate ?? new Date().toISOString();
            return data;
        });
};

export const getNativeCO2e = async (
    segregateBy: Models.PluralEntityType.subscriptions,
    params: Models.ISpendParameters,
    scope: "Scope1" | "Scope2" | "Scope3",
): Promise<Models.ICO2EResult<Models.ICO2EAndConsumptionGroup>> => {
    return await ApiV2.http
        .get(`/api/nativeco2e/${segregateBy.toLowerCase()}/${scope}`, {
            params: { ...params, fields: "CO2e" },
        })
        .then((r) =>
            r.data as Models.ICO2EResult<Models.ICO2EAndConsumptionGroup>
        )
        .then((r) => {
            r.groupings.forEach((g) => {
                g.records.forEach((r) => {
                    r.kwH = 100;
                });
            });
            return r;
        });
};

export const getCO2e = async (
    segregateBy: Models.PluralEntityType,
    params: Models.ISpendParameters,
): Promise<Models.ICO2EResult<Models.ICO2EAndConsumptionGroup>> => {
    return await ApiV2.http
        .get(`/api/co2e/${segregateBy.toLowerCase()}`, { params })
        .then((r) =>
            r.data as Models.ICO2EResult<Models.ICO2EAndConsumptionGroup>
        );
};

export const getForecastCO2e = async (
    params: Models.ISpendParameters,
    dateRangeKey: string = null,
): Promise<Models.IForecastCo2eResult> => {
    switch (dateRangeKey) {
        case "custom":
        case "lastMonth":
        case "thisMonth":
        case "all":
            break;
        default:
            if (dateRangeKey.endsWith("d") || dateRangeKey.endsWith("m")) {
                if (params.granularity === Models.Granularity.monthly) {
                    params = {
                        ...params,
                        toDate: moment.utc().add(2, "months").endOf("month")
                            .toDate().toISOString(),
                    };
                } else if (params.granularity === Models.Granularity.daily) {
                    params = {
                        ...params,
                        toDate: moment.utc().add(7, "days").toDate()
                            .toISOString(),
                    };
                }
            }
            break;
    }
    return await ApiV2.http.get(`/api/forecast/co2e`, { params })
        .catch((err) => {
            if (err.response.status != 400) {
                console.error("Error loading forecast data", err);
            }
            return { data: {} };
        })
        .then((r) => {
            const maxUsageDate = r.data.forecasts && r.data.forecasts.length > 0
                ? r.data.forecasts.reduce((max, item) => {
                    return new Date(item.usageDate) > new Date(max.usageDate)
                        ? item
                        : max;
                }).usageDate
                : new Date().toISOString();
            const data = r.data as Models.IForecastCo2eResult;
            data.maxUsageDate = maxUsageDate ?? new Date().toISOString();
            return data;
        });
};

export const getTagUsage = async (
    min: Date,
    max: Date,
    dimensions: string[],
    granularity: Models.Granularity = Models.Granularity.daily,
): Promise<Models.ISpendByTagResult> => {
    return ApiV2.http.get(
        `/api/report/tagoverview?min=${min.toISOString()}&max=${max.toISOString()}&dimensions=${
            appendValueToCommaString("", dimensions)
        }&granularity=${granularity}`,
    )
        .then((r) => r.data as Models.ISpendByTagResult);
};

export const getAllTagKeys = async (): Promise<
    Models.IAllTagKeyRequestResult
> => {
    return ApiV2.http.get("/api/tags/keys").then((r) =>
        r.data as Models.IAllTagKeyRequestResult
    );
};

export const getTrends = async <
    TType extends
        | Models.PluralEntityType.products
        | Models.PluralEntityType.services
        | Models.PluralEntityType.subscriptions
        | Models.PluralEntityType.cloudAccounts,
>(segregateBy: TType, params: Models.ICompareParameters): Promise<
    TType extends Models.PluralEntityType.products
        ? Models.ICompareResult<Models.IProductCompareResult>
        : TType extends Models.PluralEntityType.services
            ? Models.ICompareResult<Models.IServiceCompareResult>
        : TType extends Models.PluralEntityType.subscriptions
            ? Models.ICompareResult<Models.ISubscriptionCompareResult>
        : TType extends Models.PluralEntityType.cloudAccounts
            ? Models.ICompareResult<Models.ICloudAccountCompareResult>
        : never
> => {
    return await ApiV2.http
        .get(`/api/cost-explorer/${segregateBy.toLowerCase()}`, { params })
        .then((r) => r.data);
};

export const getTrendsSummary = async (
    segregateBy: Models.PluralEntityType.subscriptions,
    params: Models.IDaysRangeParameter,
): Promise<Models.IExplorerSpendBase> => {
    return await ApiV2.http
        .get(`/api/cost-explorer/${segregateBy.toLowerCase()}/summary`, {
            params,
        })
        .then((r) => r.data);
};

export const getCustomerSummary = async (
    min: Date,
    max: Date,
): Promise<Models.ICustomerUsageSummaryModel> => {
    const params = { min: min.toISOString(), max: max.toISOString() };
    return await ApiV2.http
        .get("/api/customers/summary", { params })
        .then((r) => r.data);
};

export const getRecommendations = async (): Promise<
    Models.IGetRecommendationsQueryResponse
> => {
    return await ApiV2.http
        .get("api/recommendations")
        .then((r) => r.data);
};

export const getMissingCo2eReport = async (
    params: Partial<Models.ISpendParameters>,
): Promise<Models.IMissingCO2eResult[]> => {
    return await ApiV2.http
        .get("api/report/missingco2e", { params })
        .then((r) => r.data);
};

export const getSavedReports = async (): Promise<
    Models.ISavedReportResult[]
> => {
    return await ApiV2.http.get("/api/savedreports").then((r) => r.data);
};

export const getSavedReport = async (
    id: string,
): Promise<Models.ISavedReportResult> => {
    return await ApiV2.http.get(`/api/savedreports/${id}`).then((r) => r.data);
};

export const createSavedReport = async (
    r: Models.ISavedReportResult,
): Promise<Models.ISavedReportResult> => {
    return await ApiV2.http.post("/api/savedreports", r).then((r) => r.data);
};

export const getCustomViews = async (): Promise<Models.ICustomViewResult[]> => {
    return await ApiV2.http.get("/api/customViews").then((r) => r.data);
};

export const getCustomView = async (
    id: string,
): Promise<Models.ICustomViewResult> => {
    return await ApiV2.http.get(`/api/customViews/${id}`).then((r) => r.data);
};

export const createCustomView = async (
    customView: Models.ICustomViewResult,
): Promise<Models.ICustomViewResult> => {
    return await ApiV2.http.post("/api/customViews", customView).then((r) =>
        r.data
    );
};

export const updateCustomView = async (
    id: string,
    customView: Models.ICustomViewResult,
): Promise<Models.ICustomViewResult> => {
    return await ApiV2.http.put(`/api/customViews/${id}`, customView).then((
        r,
    ) => r.data);
};

export const deleteCustomView = async (id: string): Promise<void> => {
    return await ApiV2.http.delete(`/api/customViews/${id}`).then((r) =>
        r.data
    );
};

export const getApiKeys = async (): Promise<Models.IApiKeyResult[]> => {
    return await ApiV2.http
        .get("api/apikeys")
        .then((r) => r.data);
};

export const getAllTagMappings = async (): Promise<
    Models.IConditionalActionRuleResult[]
> => {
    return await ApiV2.http
        .get("api/actions/rules")
        .then((r) => r.data);
};

export const deleteTagMapping = async (id: number): Promise<void> => {
    await ApiV2.http.delete(`api/actions/rule/${id}`);
};

export const updateTagMapping = async (
    id: number,
    model: Models.IConditionalActionRuleResult,
): Promise<Models.IConditionalActionRuleResult> => {
    return await ApiV2.http.put(`api/actions/rule/${id}`, model).then((r) =>
        r.data
    );
};

export const createTagMapping = async (
    model: Models.IConditionalActionRuleResult,
): Promise<Models.IConditionalActionRuleResult> => {
    return await ApiV2.http.post("api/actions/rule", model).then((r) => r.data);
};

export const reorderTagMappings = async (
    models: Partial<Models.IConditionalActionRuleResult>[],
): Promise<Models.IConditionalActionRuleResult[]> => {
    return await ApiV2.http.post("api/actions/rules/reorder", models).then(
        (r) => r.data
    );
};

export const getAllSpendLimits = async (): Promise<
    Models.ISpendLimitMetaResult[]
> => {
    return await ApiV2.http.get("api/metadata/spendlimits").then((r) => r.data);
};

export const getAllBudgets = async (): Promise<Models.IBudgetResult[]> => {
    return await ApiV2.http.get("api/budgets").then((r) => r.data);
};

export const getCustomViewBudgets = async (
    id: string,
): Promise<Models.IBudgetResult[]> => {
    return await ApiV2.http.get(`api/customViews/${id}/budgets`).then((r) =>
        r.data
    );
};

export const getBudget = async (id: number): Promise<Models.IBudgetResult> => {
    return await ApiV2.http.get(`api/budgets/${id}`).then((r) => r.data);
};

export const deleteBudget = async (id: number): Promise<void> => {
    await ApiV2.http.delete(`api/budgets/${id}`);
};

export const createBudget = async (
    b: Models.IBudgetCreateModel,
): Promise<Models.IBudgetResult> => {
    return await ApiV2.http.post("api/budgets", b).then((r) => r.data);
};

export const createPolicy = async (
    policyModel: Models.IPolicyModel,
): Promise<Models.IPolicyModel> => {
    return await ApiV2.http.post("/api/policies", policyModel).then((r) =>
        r.data
    );
};

export const putPolicy = async (
    policyModel: Models.IPolicyModel,
): Promise<Models.IPolicyModel> => {
    return await ApiV2.http.put(`/api/policies/${policyModel.id}`, policyModel)
        .then((r) => r.data);
};

export const listPolicies = async (): Promise<Models.IPolicyModel[]> => {
    return await ApiV2.http.get("/api/policies").then((r) => r.data);
};

export const listAllResults = async (): Promise<
    Models.IPolicyResultWithPolicyModel[]
> => {
    return await ApiV2.http.get("/api/policies/results").then((r) => r.data);
};

export const getPolicy = async (id: number): Promise<Models.IPolicyModel> => {
    return await ApiV2.http.get(`/api/policies/${id}`).then((r) => r.data);
};

export const listPolicyResults = async (
    id: number,
): Promise<Models.IPolicyResultModel[]> => {
    return await ApiV2.http.get(`/api/policies/${id}/results`).then((r) =>
        r.data
    );
};

export const downloadPolicyResultData = async (
    id: number,
    rid: number,
): Promise<any> => {
    return await ApiV2.http.get(`/api/policies/${id}/results/${rid}`).then(
        (r) => r.data
    );
};

export const getPolicySummary = async (): Promise<
    Models.IPolicySummaryModel
> => {
    return await ApiV2.http.get("api/policies/summary").then((r) => r.data);
};

export const listDimensions = async (): Promise<Models.IDimensionModel[]> => {
    return await ApiV2.http.get("/api/metadata/dimensions").then((r) => r.data);
};

export const getAllTenantWebhookNames = async (): Promise<string[]> => {
    return getWebhooks().then((r) => r.map((c) => c.name));
};

export const getMailingLists = async (): Promise<
    Models.IMailingListGetPutModel[]
> => {
    return await ApiV2.http.get("api/mailinglists").then((r) => r.data);
};

export const getWebhooks = async (): Promise<Models.IWebhookGetPutModel[]> => {
    return await ApiV2.http.get("api/webhooks").then((r) => r.data);
};

export const getWebhook = async (
    id: number,
): Promise<Models.IWebhookGetPutModel> => {
    return await ApiV2.http.get(`api/webhooks/${id}`).then((r) => r.data);
};

export const createWebhook = async (
    body: Models.IWebhookGetPutModel,
): Promise<Models.IWebhookGetPutModel> => {
    return await ApiV2.http.post("api/webhooks", body).then((r) => r.data);
};

export const createMailingList = async (
    body: Models.IMailingListGetPutModel,
): Promise<Models.IMailingListGetPutModel> => {
    return await ApiV2.http.post("api/mailinglists", body).then((r) => r.data);
};

export const putMailingList = async (
    body: Models.IMailingListGetPutModel,
): Promise<Models.IMailingListGetPutModel> => {
    return await ApiV2.http.put(`api/mailinglists/${body.id}`, body).then((r) =>
        r.data
    );
};

export const getMailingList = async (
    id: number,
): Promise<Models.IMailingListGetPutModel> => {
    return await ApiV2.http.get(`api/mailinglists/${id}`).then((r) => r.data);
};

export const getPolicyResultUrl = async (
    policyId: number,
    resultId: number,
): Promise<{ downloadUrl: string }> => {
    return await ApiV2.http.get(`/api/policies/${policyId}/results/${resultId}`)
        .then((r) => r.data);
};

export const deliverSavedReport = async (
    reportId: string,
    emails: string[],
    mailingListId?: number,
) => {
    return await ApiV2.http.post(`/api/savedreports/${reportId}/deliver`, {
        emails,
        mailingList: mailingListId,
    });
};

export const listCustomDashboards = async (): Promise<
    Models.ICustomDashboardModel[]
> => {
    return await ApiV2.http.get("/api/dashboards").then((r) => {
        return r.data;
    });
};

export const createCustomDashboard = async (
    m: OmitExcept<Models.ICustomDashboardModel, "id">,
    doc: G.CCGrid,
): Promise<Models.ICustomDashboardModel> => {
    const docString = JSON.stringify(doc);
    const docBinary = await stringToUint(docString);
    const file = new Blob([docBinary], { type: "application/octet-stream" });
    const formData = new FormData();
    formData.append("name", m.name);
    formData.append("description", m.description);
    formData.append("file", file);
    return await ApiV2.http.post("/api/dashboards", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((r) => r.data);
};

export const putCustomDashboard = async (
    id: string,
    m: OmitExcept<Models.ICustomDashboardModel, "id">,
    doc: G.CCGrid,
): Promise<Models.ICustomDashboardModel> => {
    const docString = JSON.stringify(doc);
    const docBinary = await stringToUint(docString);
    const file = new Blob([docBinary], { type: "application/octet-stream" });
    const formData = new FormData();
    formData.append("Name", m.name);
    formData.append("Description", m.description);
    formData.append("File", file);
    const putResponse = await ApiV2.http.put(
        `/api/dashboards/${id}`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    ).then((r) => r.data);
    return putResponse;
};

export const getCustomDashboardDoc = async (id: string): Promise<G.CCGrid> => {
    const response = await ApiV2.http.get(`/api/dashboards/${id}/content`, {
        responseType: "arraybuffer",
    });
    const uint8Array = new Uint8Array(response.data);
    const docString = await blobToString(new Blob([uint8Array]));
    const doc = JSON.parse(docString);
    return doc;
};

export const enableDimension = async (key: string): Promise<void> => {
    return await ApiV2.http.post("/api/metadata/dimensions/enable", {
        dimensionKey: key,
    });
};
export const disableDimension = async (key: string): Promise<void> => {
    return await ApiV2.http.post("/api/metadata/dimensions/disable", {
        dimensionKey: key,
    });
};

export const pinDimension = async (key: string): Promise<void> => {
    return await ApiV2.http.post("/api/metadata/dimensions/pin", {
        dimensionKey: key,
    });
};
export const unpinDimension = async (key: string): Promise<void> => {
    return await ApiV2.http.post("/api/metadata/dimensions/unpin", {
        dimensionKey: key,
    });
};
