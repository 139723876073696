import Vue from 'vue';

export class AuthClient {
    public get tenantId(): string {
        return localStorage.getItem('x-cc-tenantid') || null;
    }

    public set tenantId(val: string) {
        if (val) localStorage.setItem('x-cc-tenantid', val);
        else localStorage.removeItem('x-cc-tenantid');
    }

    public get isTenantSelected(): boolean {
        return !!this.tenantId;
    }

    public get isAuthenticated(): boolean {
        return !!Vue.prototype.$auth?.user?.email;
    }

    public get userEmail(): string {
        return Vue.prototype.$auth.user.email;
    }

    public get loading(): boolean {
        return Vue.prototype.$auth.loading;
    }
}

export default new AuthClient();
