import * as CssVariables from "@/lib/CssVariables";
import { Chart } from "chart.js/auto";
import { LEGACY_COLORS_RGB } from './legacy'
import { formatCurrency } from '@/lib/Currencies';

export interface IClickEvent {
    index: number;
    name: string;
    isOther: boolean;
    datasetGroupId: string;
}

export interface IOptions {
    currencyCode: string;
    onClick?: (event: IClickEvent) => void;
}

function colorize(opaque, hover, ctx) {
    const v = ctx.parsed;
    const c = v < -50
        ? "#D60000"
        : v < 0
        ? "#F46300"
        : v < 50
        ? "#0358B6"
        : "#44DE28";

    //const opacity = hover ? 1 - Math.abs(v / 150) - 0.2 : 1 - Math.abs(v / 150);

    return c;
}

function hoverColorize(ctx) {
    return colorize(false, true, ctx);
}

import { ChartData } from "chart.js";
import { ISpendAndConsumptionGroup, ISpendResult } from "@/models";

export function convertUsageToPieDataset(
    usageResult: ISpendResult<ISpendAndConsumptionGroup> | null,
    reportType: "spend" | "charges" | "credits" = "spend",
): ChartData<"pie", number[], string> {
    if (!usageResult || !usageResult.groupings?.length) {
        return { labels: [], datasets: [] };
    }

    const valueFn = {
        spend: (g: ISpendAndConsumptionGroup) => g.total,
        charges: (g: ISpendAndConsumptionGroup) => g.totalCharges,
        credits: () => 0
    }[reportType];

    const labels: string[] = [];
    const data: number[] = [];
    const backgroundColor: string[] = [];

    usageResult.groupings.forEach((g, i) => {
        const label = g.name || "All Usage";
        labels.push(label);
        data.push(valueFn(g));
        backgroundColor.push(
            g.isOther
                ? "#87db79" // or pattern if you're using it
                : `rgb(${LEGACY_COLORS_RGB[i % LEGACY_COLORS_RGB.length]})`,
        );
    });

    return {
        labels,
        datasets: [
            {
                data,
                backgroundColor,
                borderColor: "white",
                borderWidth: 1,
            },
        ],
    };
}

export default (chartItem: HTMLCanvasElement, options: IOptions): Chart => {
    const { currencyCode } = options;
    const style = getComputedStyle(chartItem);
    const contrastText = style.getPropertyValue(
        CssVariables.ContrastTextVariable,
    );
    const chart = new Chart(chartItem, {
        type: "pie",
        data: {
            datasets: [],
        },
        options: {
            plugins: {
              legend: {
                labels: {
                  color: contrastText,
                }
              },
              tooltip: {
                callbacks: {
                    label(context) {
                      const value = context.parsed;
                      const label = context.label || '';
                      return `${label}: ${formatCurrency(value, currencyCode)}`;
                    },
                  },
                bodyColor: contrastText,
                titleColor: contrastText,
              },
            }
          }
    });
    return chart;
};
