export interface ICognitoClientOptions {
    UserPoolId: string;
    ClientId: string;
    CognitoUrl: string;
    AzureIDPName: string;
}

export interface IConfig {
    APP_NAME: string;
    API_URL: string;
    API_2_URL: string;
    SUPPORTED_PARENTS: string;
    FEATURES: string[];
    APP_INSIGHTS_KEY: string;
    DEFAULT_THEME: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    LOGOS: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    SUPPORT_EMAIL: string;
    API_MAX_RETRY_COUNT: number;
    RESALE_PARTNER_ID: string;
    SIDENAV_ALWAYS_DARK: boolean;
    COGNITO_USERPOOLID: string;
    COGNITO_CLIENTID: string;
    COGNITO_DOMAIN: string;
    COGNITO_AZUREIDP: string;
    AUTH0_DOMAIN: string;
    AUTH0_CLIENTID: string;
    AUTH0_AUDIENCE: string;
    MSAL_CLIENTID: string;
    MSAL_AUTHORITY: string;
    DOCS_URL: string;
    AG_GRID_KEY: string;
    CHART_COLORS: string[];
}

export class ConfigClient {
    public get(): IConfig {
        return (window as any).$cloudctrl.config as IConfig; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    public isFeatureEnabled(featureName: string): boolean {
        const config = this.get();
        return (config.FEATURES || []).includes(featureName);
    }

    public isSelfServiceEnabled(): boolean {
        return this.isFeatureEnabled('EnableSelfService');
    }

    public isChineseJurisdiction(): boolean {
        return this.isFeatureEnabled('ChineseJurisdictionalFeatures');
    }

    public isAnnotationEnabled(): boolean {
        return this.isFeatureEnabled('EnableAnnotations');
    }

    public isMainSite(): boolean {
        return document.domain === 'cloudctrl.com.au';
    }

    public isDeprecationNoticeEnabled(): boolean {
        return this.isFeatureEnabled('DeprecateTagReports');
    }

    public getCognitoCredentials(): ICognitoClientOptions {
        const config = this.get();
        return {
            UserPoolId: config.COGNITO_USERPOOLID,
            ClientId: config.COGNITO_CLIENTID,
            CognitoUrl: config.COGNITO_DOMAIN,
            AzureIDPName: config.COGNITO_AZUREIDP
        };
    }
}

export default new ConfigClient();
