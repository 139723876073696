<template>
    <v-toolbar flat color="transparent">
        <segregation-button-toggle
            :value="value.segregateBy"
            @input="$emit('input', { ...value, segregateBy: $event })"
            :items="segregateTypes"
            hide-others
        ></segregation-button-toggle>
        <v-spacer></v-spacer>
        <v-autocomplete
            :value="value.period"
            @input="$emit('input', { ...value, period: $event })"
            :items="allItems"
            :label="$t('usageComparison.comparisontimeframe')"
            flat
            outlined
            item-text="text"
            item-value="value"
            style="max-width: 18em"
            prepend-icon="date_range"
            hide-details
            class="mr-2"
        ></v-autocomplete>
        <text-date-picker
            :value="value.toDate"
            @input="$emit('input', { ...value, toDate: $event })"
            hide-details
            :label="$t('usageComparison.currentperiodending')"
            flat
            outlined
            :prepend-icon="null"
            :max="currentPeriodMax"
            :type="value.period === 'month' ? 'month' : 'date'"
        ></text-date-picker>
        <v-progress-circular v-if="loading" class="ml-3" indeterminate color="primary"></v-progress-circular>
    </v-toolbar>
</template>

<i18n>
{
    "en": {
        "usageComparison.comparisontimeframe": "Comparison timeframe",
        "usageComparison.currentperiodending": "Current period ending"
    }
}
</i18n>

<script>
import SegregationButtonToggle from '@/components/Domain.Usage/SegregationButtonToggle.vue';
export default {
    components: { SegregationButtonToggle },
    props: {
        value: Object,
        loading: Boolean,
        currentPeriodMax: String,
        segregateTypes: {
            type: Array,
            default: () => ['products', 'services', 'subscriptions', 'cloudaccounts'],
        },
    },
    data() {
        return {
            allItems: [
                { value: 'month', text: 'Month vs. Previous Month' },
                { value: '30d', text: '30 days vs. Prior 30 days' },
                { value: '7d', text: '7 days vs. Prior 7 days' },
                { value: '1d', text: 'Day vs. Prior Day' },
            ],
        };
    },
};
</script>