
import { defineComponent, ref, watch } from 'vue';
import ApiV2 from '@/lib/ApiV2';
import { IRecommendationsWidgetData } from '@/merge/Grid';
import hash from 'object-hash';
export default defineComponent({
    props: { config: Object, name: String, hideActions: Boolean },
    setup(props) {
        const recommendations = ref<
            { id: string; name: string; category: string; resultCount: number; savings: number }[]
        >([]);

        const isLoading = ref(false);
        const lastHash = ref<string | null>(null);
        const loadRecommendations = async (d: IRecommendationsWidgetData) => {
            if (isLoading.value) return;
            const optionsHash = hash([ d.categories, d.providerTypes ]);
            if (lastHash.value === optionsHash) return;
            lastHash.value = optionsHash;

            const query = new URLSearchParams();

            if (d.categories?.length) {
                d.categories.forEach((c) => query.append('categories', c));
            }

            if (d.providerTypes?.length) {
                d.providerTypes.forEach((p) => query.append('providerTypes', p));
            }

            try {
                isLoading.value = true;

                const recommendationResponse = await ApiV2.http
                    .get(`/api/recommendations?${query.toString()}`)
                    .then((r) => r.data);

                recommendations.value = recommendationResponse.recommendations
                    .sort((a, b) => b.results.length - a.results.length)
                    .map((r: any) => ({
                        id: r.id,
                        name: r.name,
                        category: r.category,
                        resultCount: r.results.length,
                        savings: r.maximumEstimateImpact,
                    }));
            } finally {
                isLoading.value = false;
            }
        };

        watch(
            () => props.config,
            (opt) => {
                if (!opt) return;
                loadRecommendations(opt as any);
            },
            { immediate: true, deep: true }
        );

        return { recommendations, isLoading };
    },
    computed: {
        chartTitle() {
            return this.name || 'Recommendations';
        },
    },
});
