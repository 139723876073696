import { v4 } from 'uuid';
import { PartialPick } from '@/lib';
import { ISavedReportResultParams, PluralEntityType } from '@/models';

export interface Identified {
    id: string;
}

export interface Named {
    name: string;
}

export interface Described {
    description: string;
}

export interface Position {
    x: number;
    y: number;
}

export interface Rectangle {
    w: number;
    h: number;
}

export interface Typed<TType extends string> {
    type: TType;
}

export interface GridWidget<TConfig> extends Identified, Named, Position, Rectangle {
    config: TConfig;
}

export interface Grid<TElementType> extends Identified, Named, Described {
    elements: {
        [id: string]: TElementType;
    };
}

export enum WidgetTypes {
    legacyChart = 'legacyChart',

    columnChart = 'columnChart',
    lineChart = 'lineChart',
    table = 'table',
    trends = 'trends',
    singleStatistic = 'singleStatistic',
    pieChart = 'pieChart',
    recommendations = 'recommendations',

    note = 'note',

    embed = 'embed'
}

export enum ChartTypes {
    usageChart = 'usageChart',
    emissionsChart = 'emissionsChart',
}

export interface INoteWidgetData {
    title: string;
    subtitle: string;
    bodyText: string;
    color: string;
}

export interface IEmbedWidgetData {
    urlTitle: string;
    url: string;
}

export interface ITrendsWidgetData {
    toDate: string | null;
    dateRangeKey: string;
    segregateBy: PluralEntityType.products | PluralEntityType.services;
    renderType?: 'chart' | 'table';
}

export interface IRecommendationsWidgetData {
    categories: string[] | null;
    classifications: string[] | null;
    providerTypes: string[] | null;
    namespaces: string[] | null;
    recommendationIds: string[] | null;
}

export interface ISpendUsageWidgetData extends ISavedReportResultParams {
    includeAnnotations?:boolean;
}

export type UsageReportWidget = Typed<
| WidgetTypes.legacyChart
| WidgetTypes.columnChart
| WidgetTypes.lineChart
| WidgetTypes.singleStatistic
| WidgetTypes.table
| WidgetTypes.pieChart
> &
GridWidget<ISpendUsageWidgetData>;

export type NoteWidget = Typed<WidgetTypes.note> & GridWidget<INoteWidgetData>;

export type EmbedWidget = Typed<WidgetTypes.embed> & GridWidget<IEmbedWidgetData>;

export type TrendsWidget = Typed<WidgetTypes.trends> & GridWidget<ITrendsWidgetData>;

export type RecommendationWidget = Typed<WidgetTypes.recommendations> & GridWidget<IRecommendationsWidgetData>;

// If you want a new type of configuration for a widget, add an | keyed by type.
export type CCWidget = UsageReportWidget | NoteWidget | EmbedWidget | TrendsWidget | RecommendationWidget;

export type CCGrid = Grid<CCWidget>;

export const initGrid = (p: { name: string; description:string; prefix?: string }): CCGrid => {
    const { name, prefix, description } = p;
    return {
        id: prefix ? `${prefix}:${v4()}` : v4(),
        name: name,
        description: description,
        elements: {},
    };
};

export const patchGridFn = <T extends keyof CCGrid>(p: PartialPick<CCGrid, T>, grid: CCGrid): CCGrid => {
    Object.keys(p).forEach((k) => {
        if (p[k] !== undefined) {
            grid[k] = p[k];
        }
    });

    return grid;
};

export const addGridElement = (widget: CCWidget, grid: CCGrid): CCGrid => {
    const isExisting = grid.elements[widget.id];
    if (isExisting) throw new Error('Grid element already exists!');
    grid.elements[widget.id] = widget;
    return grid;
};

export const patchGridElement = <T extends keyof CCWidget>(
    id: string,
    p: PartialPick<CCWidget, T>,
    grid: CCGrid
): CCGrid => {
    const element = grid.elements[id];
    if (element) {
        Object.keys(p).forEach((k) => {
            if (p[k] !== undefined) {
                element[k] = p[k];
            }
        });
    }

    return grid;
};

export const removeGridElement = (id: string, grid: CCGrid): CCGrid => {
    delete grid.elements[id];
    return grid;
};

export const reshapeGridElements = (
    dimensions: (Partial<Position & Rectangle> & Identified)[],
    grid: CCGrid
): CCGrid => {
    if (!dimensions?.length) return;
    dimensions.forEach((d) => {
        const element = grid.elements[d.id];
        if (element) {
            if (d.h !== undefined) {
                element.h = d.h;
            }
            if (d.w !== undefined) {
                element.w = d.w;
            }
            if (d.x !== undefined) {
                element.x = d.x;
            }
            if (d.y !== undefined) {
                element.y = d.y;
            }
        } else {
            console.error('not found');
        }
    });

    return grid;
};
