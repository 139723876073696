
import SetWebhookCard from '@/components/Domain.Webhooks/SetWebhookCard.vue';
export default {
    props: { value: Boolean, loading: Boolean, disabled: Boolean, webhook: Object },
    components: { SetWebhookCard: SetWebhookCard },
    methods: {
        open() {
            this.$emit('input', true);
        },
    },
};
