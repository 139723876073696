
import { defineComponent } from 'vue';
import ColumnChartGridWidget from './ColumnChartGridWidget.vue';
import LineChartGridWidget from './LineChartGridWidget.vue';
import TableGridWidget from './TableGridWidget.vue';
import PieChartGridWidget from './PieChartGridWidget.vue';
import TextualGridWidget from './TextualGridWidget.vue';
import EmbedGridWidget from './EmbedGridWidget.vue';
import ComparisonChartGridWidget from './ComparisonChartGridWidget.vue';
import RecommendationsGridWidget from './RecommendationsGridWidget.vue';
export default defineComponent({
    components: {
        ColumnChartGridWidget,
        LineChartGridWidget,
        TableGridWidget,
        PieChartGridWidget,
        TextualGridWidget,
        EmbedGridWidget,
        ComparisonChartGridWidget,
        RecommendationsGridWidget,
    },
    props: { name: String, type: String, config: Object, hideActions: Boolean },
});
