
import { IUser, IUserGroup } from '@/models';

export const convertToUserItems = (users: IUser[], userGroups: IUserGroup[]) => {
    const userResp = (users || []).map((u) => ({
        id: u.id,
        email: u.email,
        fullName: u.fullName,
        type: 'User',
        permissions: u.permissions,
    }));
    const userGroupResp = (userGroups || []).map((ug) => ({
        id: ug.id,
        email: null,
        fullName: ug.name,
        permissions: ug.permissions,
        type: 'Group',
    }));
    return userResp.concat(userGroupResp);
};

export default {
    props: ['items', 'loading'],
    data() {
        return {
            sortedBy: 'Name',
            sortedDesc: true,
            itemsPerPage: 10,
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('name'),
                    value: 'fullName',
                    sortable: true,
                },
                {
                    text: this.$t('type'),
                    value: 'type',
                    sortable: true,
                },
                {
                    text: this.$t('permissions'),
                    value: 'permissions',
                    sortable: true,
                },
                {
                    text: this.$t('actions'),
                    value: 'Actions',
                    sortable: false,
                },
            ];
        },
    },
};
