<template>
    <v-select
        :value="value"
        @input="$emit('input', $event)"
        :items="dateRanges"
        item-text="name"
        item-value="id"
        :label="$t('dateRangeSelect.label')"
        :hide-details="hideDetails"
        :solo="solo"
        :flat="flat"
        :filled="filled"
    ></v-select>
</template>

<i18n>
{
    "en": {
        "dateRangeSelect.label": "Date range",
        "dateRangeSelect.key_30d.title": "Last 30 days",
        "dateRangeSelect.key_60d.title": "Last 60 days",
        "dateRangeSelect.key_90d.title": "Last 90 days",
        "dateRangeSelect.key_lastMonth.title": "Last month",
        "dateRangeSelect.key_thisMonth.title": "This month",
        "dateRangeSelect.key_6m.title": "Last 6 months",
        "dateRangeSelect.key_13m.title": "All usage",
        "dateRangeSelect.key_all.title": "All usage",
        "dateRangeSelect.key_custom.title": "Custom"
    }
}
</i18n>

<script>
export default {
    props: {
        value: String,
        hideDetails: {
            type: Boolean,
            default: false,
        },
        solo: Boolean,
        flat: Boolean,
        filled: Boolean,
        items: {
            type: Array,
            default: () => ['30d', '60d', '90d', 'lastMonth', 'thisMonth', '6m', 'custom'],
        },
    },
    computed: {
        dateRanges() {
            return this.items.map((i) => ({
                id: i,
                name: this.$t(`dateRangeSelect.key_${i}.title`),
            }));
        },
    },
    data() {
        return {
            dates: [],
            isCustomDateMenuOpen: false,
            customDates: [],
        };
    },
};
</script>