
import { defineComponent } from 'vue';
import Config from '@/lib/Config';
import AuthClient from '@/lib/Auth';
import { getInstance } from '@/plugins/auth';
export default defineComponent({
    data: () => ({
        isAuthenticated: false,
        loading: false,
        supportEmail: Config.get().SUPPORT_EMAIL,
    }),
    methods: {
        async startLogin() {
            const auth = getInstance();
            await auth.loginWithRedirect({
                provider: ''
            });
        },
        async startRegister() {
            const auth = getInstance();
            await auth.loginWithRedirect({
                provider: '',
                screen_hint: 'signup'
            });
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            async handler(route) {
                this.isAuthenticated = AuthClient.isAuthenticated;
                localStorage.setItem('aws-marketplace-token', route.query.token);

                if (this.isAuthenticated) {
                    await this.$router.push('/choose');
                } else {
                    AuthClient.tenantId = null;
                }
            },
        },
    },
});
