
import { useTenantStore, useWaitStore, storeToRefs, useFilterStore } from "@/stores";
import CarbonBarChart, {
  convertCO2eResponseToDatasets,
  convertProjectionsResponseToDataset,
  ReverseLookup,
  IClickEvent,
} from "@/components/Domain.Carbon/CarbonBarChart.vue";
import SpendUsageOptionsToolbarForm, {
  SpendUsageOptions,
  convertSpendOptionsToQuery,
} from "@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue";
import UsageFilterChips, {
  filterUsageParamsByFilterChips,
  IChipViewModel,
} from "@/components/Domain.Usage/UsageFilterChips.vue";
import CarbonTable, {
  convertToItemRecords,
  ICarbonTableRecordsViewModel,
} from "@/components/Domain.Carbon/CarbonTable.vue";
import { toastError } from "@/components/Common/Toast.vue";
import { defineComponent, onUnmounted, ref, watch } from "vue";
import { getCO2e, getForecastCO2e } from "@/lib/Api";
import { fromPlural } from "@/models/EntityType";
import {
  EntityType,
  PluralEntityType,
  ISubscriptionMetaResult,
} from "@/models";
import { i18n } from "@/i18n";
export default defineComponent({
  components: {
    SpendUsageOptionsToolbarForm,
    UsageFilterChips,
    CarbonTable,
    CarbonBarChart,
  },
  props: { value: Object, loading: Boolean },
  setup(p) {
    const { currencyCode } = storeToRefs(useTenantStore());
    const { is, whilst } = useWaitStore();
    const filterStore = useFilterStore();
    filterStore.setFilter({segregateBy: PluralEntityType.products})
    const spendOptions = ref<SpendUsageOptions>(filterStore.options);
    const newChartData = ref({ labels: [], datasets: [] });
    const maxDate = ref<string | null>(null);
    const tableItems = ref<ICarbonTableRecordsViewModel | null>(null);
    const filters = ref<IChipViewModel[]>([]);
    const searchSupportedTypes = ref<EntityType[]>([EntityType.service, EntityType.tag]);
    const segregationSupportedKeys = ref<PluralEntityType[]>([
      PluralEntityType.products,
      PluralEntityType.services
    ]);

    let reverseLookup: ReverseLookup | null = null;
    const loadTabUsage = async () =>
      await whilst("getSubscriptionCO2e", async () => {
        const { subscriptionId } = p.value as ISubscriptionMetaResult;
        const options: SpendUsageOptions = {
          ...spendOptions.value,
          subscriptionIds: [subscriptionId],
        };
        const segregateBy = options.segregateBy as PluralEntityType;
        let params = convertSpendOptionsToQuery(options);
        params = filterUsageParamsByFilterChips(filters.value, params);
        if (!params) return;
        const [carbonResponse, forecastResponse] = await Promise.all([
          getCO2e(segregateBy, params),
          getForecastCO2e(params, options.dateRangeKey),
        ]);
        const [usageDataset, datasetIndexes] = convertCO2eResponseToDatasets(
          carbonResponse
        );
        reverseLookup = datasetIndexes;
        const forecastDataset = convertProjectionsResponseToDataset(forecastResponse);
        newChartData.value = {
          labels: [...usageDataset.labels, ...forecastDataset.labels],
          datasets: [...usageDataset.datasets, ...forecastDataset.datasets],
        };
        maxDate.value = forecastResponse?.maxUsageDate || params.toDate;
        tableItems.value = convertToItemRecords(params, carbonResponse);
      });

    const handleDatasetClicked = ({ index, isOther, name }: IClickEvent) => {
      const datasetGroupId = reverseLookup[index];
      if (isOther) {
        spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
      } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
        toastError(i18n.t("shared.meterInformationNotSupported"));
      } else {
        filters.value.push({
          id: datasetGroupId,
          name,
          type: fromPlural(spendOptions.value.segregateBy) as EntityType,
        });
      }
    };

    watch(
      () => [p.value, spendOptions.value, filters.value],
      () => {
        if (!p.value || !spendOptions.value || is("getSubscriptionCO2e")) return;
        filterStore.setFilter(spendOptions.value);
        loadTabUsage();
      },
      { immediate: true, deep: true }
    );

    onUnmounted(() => {
        const options: SpendUsageOptions = { ...spendOptions.value, subscriptionIds: [] };
        filterStore.setFilter(options);
    });

    return {
      spendOptions,
      newChartData,
      tableItems,
      filters,
      searchSupportedTypes,
      segregationSupportedKeys,
      loadTabUsage,
      handleDatasetClicked,
      currencyCode,
      wait: { is },
      maxDate,
    };
  },
});
