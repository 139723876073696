
import moment from '@/lib/moment';
import DateKey, { granularityForDateRange } from '@/lib/DateKey';
import { ISpendParameters, Granularity, CloudProviderType, EntityType, PluralEntityType } from '@/models';
import { DEFAULT_RANGE_TYPES, IDateRangeViewModel } from '@/components/Domain.Usage/DateRangeButtonToggle.vue';
import { DEFAULT_SEGREGATION_TYPES } from '@/components/Domain.Usage/SegregationButtonToggle.vue';
import { defineComponent } from 'vue';
import { ISearchEntityViewModel } from '@/components/Common/EntitySearchAutocomplete.vue';
import { useFilterStore, useTenantStore } from '@/stores';
import { mapStores } from 'pinia';
import dayjs from 'dayjs';

export enum CostView {
    Actual = 'Actual',
    Utilized = 'Utilized',
    Amortized = 'Amortized',
}

export class SpendUsageOptions {
    public segregateBy?: PluralEntityType | PluralEntityType[];
    public dateRangeKey: string;
    public fromDate?: Date;
    public toDate?: Date;
    public granularity?: string;
    public topXResults?: number;
    public cloudAccountId?: string;
    public subscriptionIds?: string[];
    public serviceId?: string;
    public productId?: string;
    public productName?: string;
    public providerType?: string;
    public regionName?: string;
    public geographyName?: string;
    public tagKeyValue?: string;
    public tagKeyValues?: string[];
    public productCategory?: string;
    public costView?: string;
    public reservationId?: string;
    public savedViewId?: string;
    public entities?: ISearchEntityViewModel[];
    public forecastRangeKey?: string;
}

export function dateRangeForKey(options: SpendUsageOptions): [] | [Date] | [Date, Date] {
    const dateRangeKey = new DateKey(options.dateRangeKey);
    if (!dateRangeKey.isValid) return [];
    return dateRangeKey.getEffectiveDateRange(options.fromDate, options.toDate);
}

export function convertSpendOptionsToQuery(options: SpendUsageOptions): ISpendParameters {
    const range = dateRangeForKey(options);
    if (range.length !== 2) return null;
    const granularity = (options.granularity || granularityForDateRange(range)) as
        | Granularity.daily
        | Granularity.monthly;
    const params: Partial<ISpendParameters> = {
        fromDate: range[0].toISOString(),
        toDate: range[1].toISOString(),
        topXResults: options.topXResults || 10,
        granularity,
    };
    if (options.subscriptionIds && options.subscriptionIds.length > 0)
        params.subscriptionIds = options.subscriptionIds.join();
    if (options.cloudAccountId) params.cloudAccountIds = options.cloudAccountId;
    if (options.serviceId) params.serviceIds = options.serviceId;
    if (options.productId) params.productIds = options.productId;
    if (options.providerType) params.providerType = options.providerType as CloudProviderType;
    if (options.regionName) params.regionNames = options.regionName;
    if (options.geographyName) params.geographyNames = options.geographyName;
    if (options.tagKeyValue) params.tagKeyValue = options.tagKeyValue;
    if (options.tagKeyValues && options.tagKeyValues.length > 0) params.tagKeyValues = options.tagKeyValues.join(';');
    if (options.productCategory) params.productCategories = options.productCategory;
    if (options.costView) params.costView = options.costView;
    if (options.reservationId) params.reservationId = options.reservationId;
    if (options.savedViewId) params.savedViewId = options.savedViewId;
    return params as ISpendParameters;
}

export function calculateOptionsDescription(value: SpendUsageOptions, $t: (key: string, config?: any) => string) {
    if (!value?.segregateBy) return 'Usage Chart';
    const key = new DateKey(value.dateRangeKey);
    const segregateByTitle = Array.isArray(value.segregateBy)
        ? 'Multiple'
        : value.segregateBy.startsWith('tags/')
        ? value.segregateBy.split('/')[1]
        : $t(`spendUsageChartCard.${value.segregateBy.toLowerCase()}.title`);
    if (key.isCustom) {
        return $t('spendUsageChartCard.customDateTextFormat', {
            segregateBy: segregateByTitle,
            fromDate: moment.utc(value.fromDate).format("DD MMM 'YY"),
            toDate: moment.utc(value.toDate || new Date()).format("DD MMM 'YY"),
        });
    } else if (key.granularity === Granularity.daily) {
        return $t('spendUsageChartCard.fixedDateTextFormat', {
            granularity: key.granularity,
            segregateBy: segregateByTitle,
            total: key.total,
            granularityName: 'days',
        });
    } else if (key.granularity === Granularity.monthly) {
        return $t('spendUsageChartCard.fixedDateTextFormat', {
            granularity: key.granularity,
            segregateBy: segregateByTitle,
            total: key.total,
            granularityName: 'months',
        });
    } else {
        throw new Error('Unsupported date range key');
    }
}

export default defineComponent({
    props: {
        value: Object,
        hideSearch: {
            type: Boolean,
            default: false,
        },
        searchSupportedTypes: {
            type: Array,
            default: () => [
                EntityType.subscription,
                EntityType.cloudAccount,
                EntityType.service,
                EntityType.tag,
                EntityType.region,
                EntityType.geography,
                EntityType.cloudProvider,
                EntityType.customView,
            ],
        },
        dateSupportedKeys: {
            type: Array,
            default: () => DEFAULT_RANGE_TYPES,
        },
        segregationSupportedKeys: {
            type: Array,
            default: () => DEFAULT_SEGREGATION_TYPES,
        },
        hideSegregation: {
            type: Boolean,
            default: false,
        },
        hideSegregationOthers: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapStores(useTenantStore),
        dateRangeDescription() {
            if (this.value.dateRangeKey === '13m') return 'All data';
            if (this.value.dateRangeKey === 'custom') {
                const fromDate = dayjs(this.value.fromDate);
                const toDate = dayjs(this.value.toDate);

                if (fromDate.year() === toDate.year() && fromDate.month() === toDate.month()) {
                    // Same month & year: "Feb 5-10, 2025"
                    return `${fromDate.format('MMM')} ${fromDate.date()}-${toDate.date()}, ${fromDate.year()}`;
                } else if (fromDate.year() === toDate.year()) {
                    // Same year but different month: "Feb 5 - Mar 10, 2025"
                    return `${fromDate.format('MMM D')} - ${toDate.format('MMM D, YYYY')}`;
                }

                // Different years: "Dec 30, 2024 - Jan 5, 2025"
                return `${fromDate.format('MMM D, YYYY')} - ${toDate.format('MMM D, YYYY')}`;
            }
            return this.value.dateRangeKey;
        },
    },
    data() {
        return {
            isCustomDateMenuOpen: false,
            isGeneralOptionsMenuOpen: false,
            isPickingDateRange: false,
            isPickingForecastRange: false,
            selectedSearchEntity: null,
            dates: [],
        };
    },
    async mounted() {
        const filterStore = useFilterStore();
        this.selectedSearchEntity = await filterStore.fetchSelectedSearchEntity();
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                const store = useFilterStore();
                store.fetchSelectedSearchEntity().then((r) => (this.selectedSearchEntity = r));
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        handleUpdatedCostView($event: 'Utilized' | 'Actual' | undefined) {
            this.$emit('input', { ...this.value, costView: $event });
        },
        handleUpdateDateRange($event: IDateRangeViewModel) {
            const key = new DateKey($event.dateRangeKey);
            const dateRange = key.getEffectiveDateRange($event.fromDate, $event.toDate);
            const granularity = granularityForDateRange(dateRange);
            let forecastRangeKey = $event.dateRangeKey === 'custom' ? undefined : this.value.forecastRangeKey || '7d';
            if (granularity === 'Daily' && !this.value.forecastRangeKey?.endsWith('d')) {
                forecastRangeKey = '7d';
            } else if (granularity === 'Monthly' && !this.value.forecastRangeKey?.endsWith('m')) {
                forecastRangeKey = '3m';
            }
            this.$emit('input', { ...this.value, ...$event, granularity, forecastRangeKey });
            this.isPickingDateRange = false;
        },
        handleForecastDateRange($event: string) {
            this.$emit('input', { ...this.value, forecastRangeKey: $event });
        },
        handleUpdateFilterEntity(entities: ISearchEntityViewModel[]) {
            const newValue: SpendUsageOptions = {
                ...this.value,
                subscriptionIds: [],
                cloudAccountId: null,
                serviceId: null,
                tagKeyValue: null,
                tagKeyValues: [],
                regionName: null,
                providerType: null,
                geographyName: null,
                savedViewId: null,
            };

            const entityLength = entities.length;
            const subscriptionIdEntities = [];

            for (let i = 0; i < entityLength; i++) {
                const entity = entities[i];
                const latestEntity = entities[entityLength - 1];

                if (entity.Type === EntityType.tag) {
                    newValue.tagKeyValues.push(entity.Id);
                    continue;
                }

                if (latestEntity.Type !== EntityType.subscription) {
                    if (latestEntity.Type === EntityType.cloudAccount) {
                        newValue.cloudAccountId = latestEntity.Id;
                    } else if (latestEntity.Type === EntityType.service) {
                        newValue.serviceId = latestEntity.Id;
                    } else if (latestEntity.Type === EntityType.region) {
                        newValue.regionName = latestEntity.Name;
                        newValue.providerType = latestEntity.Provider;
                    } else if (latestEntity.Type === EntityType.geography) {
                        newValue.geographyName = latestEntity.Name;
                    } else if (latestEntity.Type === EntityType.cloudProvider) {
                        newValue.providerType = latestEntity.Name;
                    } else if (latestEntity.Type === EntityType.product) {
                        newValue.productId = latestEntity.Id;
                        newValue.productName = latestEntity.Name;
                    } else if (latestEntity.Type === EntityType.productCategory) {
                        newValue.productCategory = latestEntity.Id;
                    } else if (latestEntity.Type === EntityType.customView) {
                        newValue.savedViewId = latestEntity.Id;
                    }
                    this.selectedSearchEntity = [latestEntity];
                    break;
                } else {
                    newValue.subscriptionIds.push(entity.Id);
                    subscriptionIdEntities.push(entity);
                    continue;
                }
            }
            if (subscriptionIdEntities.length > 0) {
                this.selectedSearchEntity = subscriptionIdEntities;
            }
            newValue.entities = this.selectedSearchEntity;
            this.$emit('input', newValue);
        },
    },
});
