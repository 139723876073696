import {
    Granularity,
    ICO2EAndConsumptionGroup,
    ICO2EResult,
    IForecastCo2eResult,
    ISpendAndConsumptionGroup,
    ISpendResult,
} from "@/models";
import * as CssVariables from "@/lib/CssVariables";
import { Chart, ChartData, ChartDataset, Point } from "chart.js/auto";
import { LEGACY_COLORS_RGB } from "./legacy";
import pattern from "patternomaly";
import "./dayJsAdapter";
import htmlLegendPlugin from "./legendPlugin";

export type ReverseLookup = { [index: number]: string };

const TOTAL_ENERGY_ORDER = 1;
const PREFIX_PAD = 100;
const OTHERS_DATASET_ORDER = 99998;
const PROJECTIONS_DATASET_ORDER = 99999;

export interface IConvertUsageReportOpts {
    labelSuffix?: string | null;
    stackGroup?: string | null;
    colorsArray?: string[] | null;
}

export const convertUsageResponseToEmissionsDatasets = (
    usageResult: ISpendResult<ISpendAndConsumptionGroup> | null,
    opts?: IConvertUsageReportOpts,
): [ChartData<"bar" | "line", Point[], string>, ReverseLookup] => {
    const { labelSuffix, stackGroup, colorsArray } = opts || {};
    const datasets: ChartDataset<"bar" | "line", Point[]>[] = [];
    const lookup: ReverseLookup = {};
    const colours = colorsArray ? colorsArray : LEGACY_COLORS_RGB;
    (usageResult?.groupings || []).reverse().forEach((g, i) => {
        lookup[i + PREFIX_PAD] = g.id;
        const color = colours[i % colours.length];
        const fullColor = `rgb(${color})`;
        const dataset: ChartDataset<"bar", Point[]> = {
            data: [],
            label: (g.type === "" ? "Tenant" : g.name) +
                (labelSuffix ? ` (${labelSuffix})` : ""),
            backgroundColor: g.isOther
                ? pattern.draw("diagonal", "#87db79", "#313e4d")
                : fullColor,
            borderColor: "white",
            hoverBackgroundColor: g.isOther
                ? pattern.draw("diagonal", "#FF10F0", "#313e4d")
                : "#FF10F0",
            borderWidth: 1,
            order: g.isOther ? OTHERS_DATASET_ORDER : (PREFIX_PAD + i),
            stack: stackGroup ? stackGroup : "stack0",
        };
        g.usageRecords.forEach((u) => {
            dataset.data.push({
                x: new Date(u.usageDate).getTime(),
                y: u.cO2e,
            });
        });
        datasets.push(dataset);
    });
    const energyDataset: ChartDataset<"line", Point[]> = {
        data: usageResult.aggregateRecords.map((u) => ({
            x: new Date(u.usageDate).getTime(),
            y: u.kWh,
        })),
        label: "Total Energy (kWh)" + (labelSuffix ? ` (${labelSuffix})` : ""),
        type: "line",
        borderWidth: 3,
        backgroundColor: "rgba(255, 0, 0, 0.8)",
        borderColor: "rgba(255, 0, 0, 0.8)",
        fill: false,
        tension: 0.1,
        yAxisID: "y2",
        order: TOTAL_ENERGY_ORDER,
    };
    datasets.push(energyDataset);
    return [{
        labels: [],
        datasets,
    }, lookup];
};

export const convertCO2eResponseToDatasets = (
    usageResult: ICO2EResult<ICO2EAndConsumptionGroup> | null,
    hideKwh = false,
): [ChartData<"bar" | "line", Point[], string>, ReverseLookup] => {
    const datasets: ChartDataset<"bar" | "line", Point[]>[] = [];
    const lookup: ReverseLookup = {};
    (usageResult?.groupings || []).reverse().forEach((g, i) => {
        lookup[i + PREFIX_PAD] = g.id;
        const color = LEGACY_COLORS_RGB[i % LEGACY_COLORS_RGB.length];
        const fullColor = `rgb(${color})`;
        const dataset: ChartDataset<"bar", Point[]> = {
            data: [],
            label: g.name,
            backgroundColor: g.isOther
                ? pattern.draw("diagonal", "#87db79", "#313e4d")
                : fullColor,
            borderColor: "white",
            hoverBackgroundColor: g.isOther
                ? pattern.draw("diagonal", "#FF10F0", "#313e4d")
                : "#FF10F0",
            borderWidth: 1,
            order: g.isOther ? OTHERS_DATASET_ORDER : (PREFIX_PAD + i),
        };
        g.records.forEach((u) => {
            dataset.data.push({
                x: new Date(u.usageDate).getTime(),
                y: u.cO2E,
            });
        });
        datasets.push(dataset);
    });
    if (!hideKwh) {
        const energyDataset: ChartDataset<"line", Point[]> = {
            data: usageResult.aggregateRecords.map((u) => ({
                x: new Date(u.usageDate).getTime(),
                y: u.kwH,
            })),
            label: "Total Energy (kWh)",
            type: "line",
            borderWidth: 3,
            backgroundColor: "rgba(255, 0, 0, 0.8)",
            borderColor: "rgba(255, 0, 0, 0.8)",
            fill: false,
            tension: 0.1,
            yAxisID: "y2",
            order: TOTAL_ENERGY_ORDER,
        };
        datasets.push(energyDataset);
    }
    return [{
        labels: [],
        datasets,
    }, lookup];
};

export const convertProjectionsResponseToDataset = (
    projectionsResult: IForecastCo2eResult | null,
): ChartData<"bar", Point[], string> => {
    const dataset: ChartDataset<"bar", Point[]> = {
        data: [],
        label: "Forecast",
        backgroundColor: pattern.draw("diagonal", "#3d3a60", "#fff"),
        borderColor: "white",
        borderWidth: 1,
        order: PROJECTIONS_DATASET_ORDER,
    };
    (projectionsResult?.forecasts || []).forEach((u) => {
        const forecastTimestamp = new Date(u.usageDate).getTime();
        dataset.data.push({
            x: forecastTimestamp,
            y: u.forecast,
        });
    });
    return {
        labels: [],
        datasets: [dataset],
    };
};

export interface IClickEvent {
    index: number;
    name: string;
    isOther: boolean;
}

export interface IOptions {
    onClick?: (event: IClickEvent) => void;
    granularity: Granularity.daily | Granularity.monthly;
    type?: "bar" | "line";
    maxDate?: string;
    hideKwh: boolean;
}

export default (
    chartItem: HTMLCanvasElement,
    options: IOptions,
    containerId: string,
): Chart => {
    const { onClick, granularity, type, maxDate } = options;

    const style = getComputedStyle(chartItem);
    const contrastLines = style.getPropertyValue(
        CssVariables.ContrastLinesVariable,
    );
    const contrastText = style.getPropertyValue(
        CssVariables.ContrastTextVariable,
    );
    const onClickProcessor = (_, [element], chart) => {
        if (!element) return;
        const dataset = chart.data.datasets[element.datasetIndex];
        if (
            !dataset || dataset.order === PROJECTIONS_DATASET_ORDER ||
            dataset.order === TOTAL_ENERGY_ORDER
        ) return;
        if (onClick) {
            onClick({
                index: dataset.order,
                isOther: dataset.order === OTHERS_DATASET_ORDER,
                name: dataset.label,
            });
        }
    };
    const scales: any = {
        x: {
            type: "time",
            stacked: true,
            ticks: {
                color: contrastText,
            },
            grid: {
                color: "rgba(0, 0, 0, 0)",
            },
            axis: "x",
            time: {
                unit: granularity == Granularity.monthly ? "month" : "day",
            },
            bounds: "ticks",
            max: new Date(maxDate ?? Date.now()).getTime(),
        },
        y: {
            type: "linear",
            stacked: true,
            ticks: {
                color: contrastText,
                callback(value: number) {
                    return `${value.toFixed(4)} t CO2e`;
                },
            },
            grid: {
                color: contrastLines,
            },
            min: 0,
            axis: "y",
            title: {
                text: `Emissions (t CO2e)`,
                color: contrastText,
                display: true,
            },
            stack: "y",
            display: true,
        },
    };
    if (!options.hideKwh) {
        scales.y2 = {
            type: "linear",
            stacked: false,
            title: {
                text: "Energy (kWh)",
                display: true,
                color: contrastText,
            },
            ticks: {
                color: contrastText,
                callback(value: number) {
                    return value.toFixed(2) + " kWh";
                },
            },
            position: "right",
            grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
        };
    }
    const chart = new Chart(chartItem, {
        type: type || "bar",
        data: {
            labels: [],
            datasets: [],
        },
        options: {
            borderColor: "rgba(0,0, 0, 0)",
            plugins: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                htmlLegend: {
                    // ID of the container to put the legend in
                    containerID: containerId,
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                    labels: {
                        color: contrastText,
                    },
                },
                tooltip: {
                    callbacks: {
                        label(item) {
                            return [item.dataset.label];
                        },
                        afterLabel(item) {
                            const dataset = item.dataset;
                            const points: Point[] = dataset.data as any;
                            const hoverPoint: Point = points[item.dataIndex];
                            const datasetTotal = points.map((a: Point) => a.y)
                                .reduce((a, b) => a + b);
                            const datasetAverage = datasetTotal /
                                dataset.data.length;
                            const percentageOfAverage =
                                (hoverPoint.y - datasetAverage) /
                                datasetAverage * 100;
                            return [
                                `Total: ${hoverPoint.y.toFixed(8)} t CO2e`,
                                `${
                                    percentageOfAverage > 0
                                        ? "+" + percentageOfAverage.toFixed(2)
                                        : percentageOfAverage.toFixed(2)
                                }% vs. average`,
                            ];
                        },
                    },
                    mode: "point",
                },
            },
            interaction: {
                intersect: true,
                mode: "dataset",
            },
            onHover(event, [element]) {
                let newCursor = "default";
                if (element) {
                    const dataset = chart.data.datasets[element.datasetIndex];
                    if (
                        dataset &&
                        dataset.order !== PROJECTIONS_DATASET_ORDER &&
                        dataset.order !== TOTAL_ENERGY_ORDER
                    ) {
                        newCursor = "pointer";
                    }
                }
                (event.native.target as HTMLElement).style.cursor = newCursor;
            },
            responsive: true,
            maintainAspectRatio: false,
            onClick(_, [element], chart) {
                onClickProcessor(_, [element], chart);
            },
            scales,
        },
        plugins: [htmlLegendPlugin],
    });
    return chart;
};
