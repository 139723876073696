import { render, staticRenderFns } from "./EmbedGridWidget.vue?vue&type=template&id=183d3c8d&scoped=true&"
import script from "./EmbedGridWidget.vue?vue&type=script&lang=ts&"
export * from "./EmbedGridWidget.vue?vue&type=script&lang=ts&"
import style0 from "./EmbedGridWidget.vue?vue&type=style&index=0&id=183d3c8d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183d3c8d",
  null
  
)

export default component.exports