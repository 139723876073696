import { getInstance } from './index';

export const authGuard = (to, from, next) => {
    const auth = getInstance();

    const fn = () => {
        // If the user is authenticated, continue with the route
        if (auth.isAuthenticated) {
            return next();
        }

        // Otherwise, log in
        auth.loginWithRedirect({ appState: { returnTo: to.fullPath } });
    };

    // If loading has already finished, check our auth state using `fn()`
    if (!auth.loading) {
        return fn();
    }

    // Watch for the loading property to change before we check isAuthenticated
    auth.$watch('loading', loading => {
        if (loading === false) {
            return fn();
        }
    });
};
