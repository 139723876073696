import { Auth0Client } from '@auth0/auth0-spa-js';
import Config from '@/lib/Config';

const config = Config.get();

const instance = new Auth0Client({
    domain: config.AUTH0_DOMAIN,
    clientId: config.AUTH0_CLIENTID,
    authorizationParams: {
        audience: config.AUTH0_AUDIENCE,
        redirect_uri: window.location.origin
    }
});

const initialize = async ({ onRedirectCallback }: { onRedirectCallback: (returnTo: string) => void }) => {
    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        const { appState } = await instance.handleRedirectCallback();
        onRedirectCallback(appState?.returnTo || window.location.pathname);
    }

    if (!await instance.isAuthenticated()) {
        await loginWithRedirect({});
    }
}

const loginWithRedirect = async (options: any) => {
    await instance.loginWithRedirect({ appState: { returnTo: window.location.pathname }, ...options });
}

const getTokenSilently = async () => {
    try {
        return await instance.getTokenSilently();
    } catch (error) {
        instance.loginWithRedirect({});
    }
}

const isAuthenticated = async () => {
    return await instance.isAuthenticated();
}

const logoutWithRedirect = async () => {
    await instance.logout({ logoutParams: { returnTo: window.location.origin }});
}

const getUser = () => {
    return instance.getUser();
}

export default {
    instance,
    initialize,
    isAuthenticated,
    getTokenSilently,
    getUser,
    loginWithRedirect,
    logoutWithRedirect
};