
import { computed, ref, onMounted } from 'vue';
import { getAllTenantWebhookNames } from '@/lib/Api';
import { IWebhookGetPutModel } from '@/models';
export default {
    props: { loading: Boolean, init: Object },
    setup(props) {
        const id = ref<number>(props.init?.id || -1);
        const name = ref<string>(props.init?.name || '');
        const currentName = ref<string>(name.value || '');
        const url = ref<string>(props.init?.url || '');
        const enabled = ref<boolean>(props.init?.enabled || true);
        const isValid = ref<boolean>(false);

        const existingNames = ref<string[]>([]);
        const nameExistsRule = (value: string) => {
            return currentName.value == name.value || !existingNames.value.includes(value)
            ? true
            : "This name already exists.";
        };

        onMounted(async () => {
            existingNames.value = await getAllTenantWebhookNames();
        });

        const form = computed(() => {
            return {
                id: id.value,
                name: name.value,
                url: url.value,
                enabled: enabled.value,
                lastcalled: '',
            } as IWebhookGetPutModel
        });

        return {
            id,
            name,
            url,
            enabled,
            isValid,
            form,
            existingNames,
            nameExistsRule
        };
    },
    watch: {
        'init.id': function (newVal, oldVal) {
            this.id = newVal;
        },
        'init.name': function (newVal, oldVal) {
            this.name = newVal;
        },
        'init.url': function (newVal, oldVal) {
            this.url = newVal;
        },
        'init.enabled': function (newVal, oldVal) {
            this.enabled = newVal;
        },
    },
};
