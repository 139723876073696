
  import { defineComponent, ref, computed, watch } from 'vue';
  import SpendUsagePieChart, {
    convertUsageToPieDataset,
    IClickEvent,
  } from '@/components/Domain.Usage/SpendUsagePieChart.vue';
  import { useFilterStore } from '@/stores';
  import {
    calculateOptionsDescription,
    convertSpendOptionsToQuery,
  } from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
  import { getSpend } from '@/lib/Api';
  import ApiV2 from '@/lib/ApiV2';
  import hash from 'object-hash';
  import { ChartData } from 'chart.js';
  import moment from '@/lib/moment';
  import {
    generateSpendUsageOptionsFromReport,
    ISavedReportResultParams,
    SpendFieldNames,
    PluralEntityType,
  } from '@/models';
  import { UsageReportTypes } from '@/chartjs/createUsageBarChart';
  import { Annotation } from '@/models/Annotation';
  import AnnotationsTimeline from '@/components/Domain.Annotations/AnnotationsTimeline.vue';
  import { ISpendUsageWidgetData } from '@/merge/Grid'
  
  export default defineComponent({
    props: {
      config: Object,
      name: String,
      hideActions: Boolean,
    },
    components: {
      SpendUsagePieChart,
      AnnotationsTimeline,
    },
    data() {
      return {
        selectedAnnotations: [],
        annotationDialog: false,
      };
    },
    setup(props) {
      const filterStore = useFilterStore();
  
      const usageOptions = ref({ ...filterStore.options });
      const segregations = ref<string[]>([]);
      const focusedSegregation = ref<number>(0);
      const pieChartData = ref<ChartData<'pie'> | null>(null);
      const costViews = ref(['Actual']);
      const dataset = ref('Usage'); // placeholder, kept for symmetry
      const includeAnnotations = ref(false);
      const isLoadingUsage = ref(false);
      const lastHash = ref<string | null>(null);
      const createdAnnotations = ref<Annotation[]>([]);
      const annotations = ref<any[]>([]);
      const maxDate = ref<string | null>(null);
  
      const handleDatasetClicked = (event: IClickEvent) => {
        // emit or handle pie slice click here
        console.log('Pie slice clicked', event);
      };
  
      const loadUsage = async () => {
        if (isLoadingUsage.value) return;
        const currentHash = hash([usageOptions.value, costViews.value, includeAnnotations.value]);
        if (lastHash.value === currentHash) return;
  
        lastHash.value = currentHash;
        isLoadingUsage.value = true;
  
        try {
          const params = convertSpendOptionsToQuery(usageOptions.value);
          if (!params) return;
  
          const spendParams = {
            ...params,
            segregateBy: usageOptions.value.segregateBy,
            fields: [SpendFieldNames.Charges],
          };
  
          const response = await getSpend(spendParams);
          pieChartData.value = convertUsageToPieDataset(response, UsageReportTypes.charges);
          maxDate.value = response?.maxDate || params.toDate;
  
          if (includeAnnotations.value) {
            createdAnnotations.value = await ApiV2.http
              .get('/api/annotations', { params })
              .then((r) => r.data);
  
            // You can implement convertAnnotatesToPieAnnotations if desired (optional)
            // annotations.value = convertAnnotatesToPieAnnotations(...);
          } else {
            createdAnnotations.value = [];
            annotations.value = [];
          }
        } finally {
          isLoadingUsage.value = false;
        }
      };
  
      // Update usage options when config changes
      watch(
        () => props.config,
        (opt) => {
          if (!opt) return;
          const report = opt as ISpendUsageWidgetData;
          segregations.value = (report.segregateBy || '').split(',').filter(Boolean);
          const params = generateSpendUsageOptionsFromReport(report);
          const segregateBy = segregations.value[focusedSegregation.value] as PluralEntityType;
          usageOptions.value = { ...params, segregateBy };
          costViews.value = report.costViews || ['Actual'];
          includeAnnotations.value = report.includeAnnotations || false;
        },
        { immediate: true, deep: true }
      );
  
      // React to focused segregation changes
      watch(
        () => focusedSegregation.value,
        (v) => {
          const segregateBy = segregations.value[v] as PluralEntityType;
          if (usageOptions.value.segregateBy !== segregateBy) {
            usageOptions.value = { ...usageOptions.value, segregateBy };
          }
        }
      );
  
      const loadTriggers = computed(() => [usageOptions.value, costViews.value]);
  
      return {
        usageOptions,
        handleDatasetClicked,
        pieChartData,
        annotations,
        isLoadingUsage,
        focusedSegregation,
        segregations,
        dataset,
        costViews,
        lastHash,
        loadTriggers,
        maxDate,
        createdAnnotations,
        loadUsage
      };
    },
    methods: {
      toTrends(searchParams: URLSearchParams) {
        this.$router.push(`/trends?${searchParams.toString()}`);
      },
      onAnnotationSelection(annotations: Annotation[], xMin: number, xMax: number) {
        const to = new Date(xMax).getTime();
        const from = new Date(xMin).getTime();
        this.selectedAnnotations = annotations.filter((x) => {
          const date = new Date(x.annotationDate).getTime();
          return date >= from && date <= to;
        });
        this.annotationDialog = true;
      },
    },
    watch: {
      loadTriggers: {
        handler() {
          this.loadUsage(this.toTrends, this.onAnnotationSelection);
        },
        immediate: true,
      },
    },
    computed: {
      chartTitle() {
        return this.name || calculateOptionsDescription(this.usageOptions, (...params) => this.$t(...params));
      },
      annotationDialogSubtitle() {
        if (this.selectedAnnotations?.length > 0) {
          const dates = this.selectedAnnotations
            .map((x) => {
              const date = new Date(x.annotationDate);
              date.setHours(0, 0, 0, 0);
              return date;
            })
            .sort((a, b) => a.getTime() - b.getTime());
          const start = moment(dates[0]);
          const end = moment(dates[dates.length - 1]);
          return start.isSame(end) ? start.format('MMMM DD') : `${start.format('MMMM DD')} - ${end.format('MMMM DD')}`;
        }
        return undefined;
      },
    },
  });
  