
import { defineComponent, ref, watch, computed } from 'vue';
import { getTrends } from '@/lib/Api';
import TrendBarChart, {
    convertProductCompareResultToDatasets,
    convertServiceCompareResultToDatasets,
    convertCloudAccountCompareResultToDatasets,
    convertSubscriptionCompareResultToDatasets,
    ITrendsDatapoint,
} from '@/components/Domain.Usage/TrendBarChart.vue';
import { PluralEntityType } from '@/models';
import { ITrendsWidgetData } from '@/merge/Grid';
import { ChartData } from 'chart.js/auto';
import ServiceComparisonsTable from '@/components/Domain.Services/ServiceComparisonsTable.vue';
import ProductComparisonsTable from '@/components/Domain.Products/ProductComparisonsTable.vue';
import SubscriptionComparisonsTable from '@/components/Domain.Subscriptions/SubscriptionComparisonsTable.vue';
import CloudAccountComparisonsTable from '@/components/Settings.CloudAccounts/CloudAccountComparisonsTable.vue';
import { useTenantStore } from '@/stores/tenant';
import hash from 'object-hash';

export default defineComponent({
    props: {
        name: String,
        hideActions: Boolean,
        config: {
            type: Object,
            required: true,
        },
    },
    components: {
        TrendBarChart,
        ServiceComparisonsTable,
        ProductComparisonsTable,
        SubscriptionComparisonsTable,
        CloudAccountComparisonsTable,
    },
    setup(props) {
        const isLoading = ref(false);
        const trendDatasets = ref<ChartData<'bar', ITrendsDatapoint[], string>>({ labels: [], datasets: [] });
        const comparisons = ref([]);
        const tenantStore = useTenantStore();

        const lastHash = ref<string | null>(null);
        const fetchTrends = async () => {
            if (!props.config) return;
            if (isLoading.value) return;
            const trendsConfig = props.config as ITrendsWidgetData;
            const optionsHash = hash([props.config.segregateBy, trendsConfig]);
            if (lastHash.value === optionsHash) return;
            lastHash.value = optionsHash;
            isLoading.value = true;
            try {
                const result = await getTrends(props.config.segregateBy, {
                    period: trendsConfig.dateRangeKey,
                    toDate: trendsConfig.toDate || new Date().toISOString(),
                });
                switch (props.config.segregateBy) {
                    case PluralEntityType.products:
                        trendDatasets.value = convertProductCompareResultToDatasets(result as any) as any;
                        break;
                    case PluralEntityType.services:
                        trendDatasets.value = convertServiceCompareResultToDatasets(result as any) as any;
                        break;
                    case PluralEntityType.subscriptions:
                        trendDatasets.value = convertSubscriptionCompareResultToDatasets(result as any) as any;
                        break;
                    case PluralEntityType.cloudAccounts:
                        trendDatasets.value = convertCloudAccountCompareResultToDatasets(result as any) as any;
                        break;
                }
                comparisons.value = result.entities || [];
            } finally {
                isLoading.value = false;
            }
        };

        watch(() => props.config, fetchTrends, { immediate: true, deep: true });

        const chartTitle = computed(() => `Trends - ${props.config.dateRangeKey}`);

        const handleChartClick = (event) => {
            console.log('Chart clicked', event);
        };

        return {
            isLoading,
            trendDatasets,
            chartTitle,
            handleChartClick,
            comparisons,
            tenantStore,
        };
    },
});
