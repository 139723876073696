
import { defineComponent, ref } from 'vue';
import { getWebhooks, getWebhook } from '@/lib/Api';
import CreateWebhookDialog from '@/components/Domain.Webhooks/CreateWebhookDialog.vue';
import EditWebhookDialog from '@/components/Domain.Webhooks/EditWebhookDialog.vue';

interface IWebhookViewModel {
    id: number;
    name: string;
    url: string;
    lastcalled: string;
    enabled: boolean;
}

export default defineComponent({
    computed: {
        headers() {
            return [
                {
                    text: this.$t("name"),
                    value: "name",
                    sortable: true,
                },
                {
                    text: this.$t("views.webhooks.url"),
                    value: "url",
                    sortable: false,
                    width: '650px'
                },
                {
                    text: this.$t("views.webhooks.lastcalled"),
                    value: "lastCalled",
                    sortable: false,
                },
                {
                    text: this.$t("views.webhooks.enabled"),
                    value: "enabled",
                    sortable: false,
                },
                {
                    text: this.$t('actions'),
                    value: "Actions",
                    sortable: false
                }
            ];
        },
    },
    components: { CreateWebhookDialog, EditWebhookDialog },
    setup() {
        const webhooks = ref<IWebhookViewModel[]>([]);
        const isEditing = ref(false);
        const isAdding = ref(false);
        const targetWebhook = ref<Partial<IWebhookViewModel>>({});
        const loading = ref(false);
        const getPageData = async () => {
            try {
                loading.value = true;
                webhooks.value = await getWebhooks();
            } finally {
                loading.value = false;
            }
        };
        getPageData();
        const startEditing = async (body: IWebhookViewModel) => {
            targetWebhook.value = await getWebhook(body.id);
            isEditing.value = true;
        };
        return {
            webhooks,
            getPageData,
            isEditing,
            targetWebhook,
            startEditing,
            loading,
            isAdding,
        };
    },
});
