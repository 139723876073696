
import WebhookCardDialog from '@/components/Domain.Webhooks/WebhookCardDialog.vue';
import CreateWebhookButton from '@/components/Domain.Webhooks/CreateWebhookButton.vue';
import { toastSuccess } from '@/components/Common/Toast.vue';
export default {
    components: { WebhookCardDialog, CreateWebhookButton },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        webhook: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleSuccessfulCreation(form) {
            toastSuccess(this.$t('createWebhookDialog.events.successfulCreation', { webhookName: form.name }));
            this.$emit('http2xx', form);
        },
    },
};
