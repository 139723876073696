<template>
    <fragment>
        <v-container>
            <content-card :title="$t('views.users.title')" :caption="$t('views.users.subtitle')" class="pb-1">
                <template #title-actions>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="secondary" depressed class="mr-3" v-bind="attrs" v-on="on">
                                <v-icon left>add</v-icon> {{ $t('users.inviteUserOrGroup') }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="startCreateAccount()">
                                <v-list-item-icon>
                                    <v-icon>mail</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Invite new user</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="startAddUserGroup()">
                                <v-list-item-icon>
                                    <v-icon>person_add</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Grant permissions to group</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <users-table :items="userItems" :loading="$wait.is('getUsers')">
                    <template #[`item.menu`]="{ item }">
                        <v-menu offset-y v-if="!item.IsMe">
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list light v-if="item.type === 'User'">
                                <v-list-item @click="startEditAccount(item)" v-if="!item.isTenantCreator">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="startDeleteAccount(item)">
                                    <v-list-item-icon>
                                        <v-icon>delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-list light v-else>
                                <v-list-item @click="startEditUserGroup(item)" v-if="!item.isTenantCreator">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="startDeleteUserGroup(item)">
                                    <v-list-item-icon>
                                        <v-icon>delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <span v-else>N/A</span>
                    </template>
                </users-table>
                <template #actions>
                    <slot name="actions"></slot>
                </template>
            </content-card>
        </v-container>
        <form-dialog v-model="dialogs.createOrUpdateUser" #default="{ close }">
            <set-user-card :loading="$wait.is('createUser')" :value="userToEdit">
                <template #actions="{ form, formId, isValid, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <add-user-button
                        v-if="!userToEdit"
                        :value="form"
                        :clear="clear"
                        :valid="isValid"
                        :form="formId"
                        :prefix="prefix"
                        v-wait-while="'createUser'"
                        @http2xx="close().then(getUsers)"
                    >
                    </add-user-button>
                    <update-user-button
                        v-else
                        :value="form"
                        :clear="clear"
                        :valid="isValid"
                        :form="formId"
                        :prefix="prefix"
                        v-wait-while="'createUser'"
                        @http2xx="close().then(getUsers)"
                    >
                    </update-user-button>
                </template>
            </set-user-card>
        </form-dialog>
        <form-dialog v-model="dialogs.addUserGroupPermission" #default="{ close }">
            <SetUserGroupPermissionsCard
                :value="userGroupToEdit"
                :loading="$wait.is('addingUserGroup')"
                :groups="userGroups"
                :disabled="userGroupToEdit ? { groupId: true } : null"
            >
                <template #actions="{ form, formId, loading, isValid }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        type="submit"
                        :form="formId"
                        :disabled="loading || !isValid"
                        :loading="loading"
                        @click="tryAddOrUpdateGroup(form, close)"
                    >
                        <v-icon left>done</v-icon>Submit
                    </v-btn>
                </template>
            </SetUserGroupPermissionsCard>
        </form-dialog>
        <form-dialog v-model="dialogs.deleteUser" #default="{ close }">
            <delete-user-card
                :loading="$wait.is('deleteUser')"
                :value="userToDelete"
                :title="$t('users.DeleteUserTitle')"
                :subtitle="$t('users.DeleteUserSubtitle')"
                :simple="true"
            >
                <template #actions="{ isValid, formId, clear }">
                    <back-button @click="close()"></back-button>
                    <v-spacer></v-spacer>
                    <delete-user-button
                        :value="userToDelete"
                        :clear="clear"
                        :valid="isValid"
                        :form="formId"
                        :prefix="prefix"
                        v-wait-while="'deleteUser'"
                        @http2xx="close().then(getUsers)"
                    >
                    </delete-user-button>
                </template>
            </delete-user-card>
        </form-dialog>
        <DeleteEntityDialog
            v-model="dialogs.deleteUserGroup"
            :entityToDelete="userGroupToRemove"
            entityType="User Group"
            resourceApi="/api/metadata/tenant/current/delegatedusergroups"
            @http2xx="getUsers()"
        >
        </DeleteEntityDialog>
    </fragment>
</template>

<i18n>
{
    "en": {
        "views.users.title": "Access Management",
        "views.users.subtitle": "The users and groups that have some level of access of this tenant.",
        "users.inviteUserOrGroup": "Add User / Group",
        "users": "Users",
        "users.DeleteUserTitle": "Delete User",
        "users.DeleteUserSubtitle": "This user will be removed from this tenant. If this was a mistake you can re-invite them and their access will be renewed."
    }
}
</i18n>

<script>
import ApiV2 from '@/lib/ApiV2';
import { waitFor } from '@/plugins/vue-wait';
import SetUserCard from '@/components/Settings.Users/SetUserCard.vue';
import UsersTable, { convertToUserItems } from '@/components/Settings.Users/UsersTable.vue';
import AddUserButton from '@/components/Settings.Users/AddUserButton.vue';
import UpdateUserButton from '@/components/Settings.Users/UpdateUserButton.vue';
import DeleteUserCard from '@/components/Common/DeleteEntityCard.vue';
import DeleteUserButton from '@/components/Settings.Users/DeleteUserButton.vue';
import SetUserGroupPermissionsCard from '@/components/Settings.Users/SetUserGroupPermissionsCard.vue';
import DeleteEntityDialog from '@/components/Common/DeleteEntityDialog.vue';

export default {
    title(ctx) {
        return ctx.$t('views.users.title');
    },
    components: {
        SetUserCard,
        UsersTable,
        AddUserButton,
        UpdateUserButton,
        DeleteUserButton,
        DeleteUserCard,
        SetUserGroupPermissionsCard,
        DeleteEntityDialog
    },
    data() {
        return {
            me: null,
            users: [],
            userGroups: [],
            deletegatedUserGroups: [],
            userToDelete: null,
            userToEdit: null,
            userGroupToEdit: null,
            userGroupToRemove: null,
            dialogs: {
                deleteUser: false,
                createOrUpdateUser: false,
                addUserGroupPermission: false,
                deleteUserGroup: false,
            },
            prefix: '/api/metadata/tenant/current/users',
        };
    },
    computed: {
        userItems() {
            return convertToUserItems(this.users, this.deletegatedUserGroups);
        },
    },
    methods: {
        startCreateAccount() {
            this.userToEdit = null;
            this.dialogs.createOrUpdateUser = true;
        },
        startAddUserGroup() {
            this.userGroupToEdit = null;
            this.dialogs.addUserGroupPermission = true;
        },
        startEditUserGroup(group) {
            this.userGroupToEdit = { groupId: group.id, permissions: group.permissions };
            this.dialogs.addUserGroupPermission = true;
        },
        startDeleteAccount(user) {
            this.userToDelete = user;
            this.dialogs.deleteUser = true;
        },
        startEditAccount(user) {
            this.userToEdit = user;
            this.dialogs.createOrUpdateUser = true;
        },
        startDeleteUserGroup(group) {
            this.userGroupToRemove = group;
            this.dialogs.deleteUserGroup = true;
        },
        async tryAddOrUpdateGroup(form, close) {
            await ApiV2.http
                .put(`api/metadata/tenant/current/delegatedusergroups/${form.groupId}`, form.permissions)
                .then((r) => r.data);
            this.getUsers();
            close();
        },
        getUsers: waitFor('getUsers', async function () {
            const [users, userGroups, delegatedUserGroups] = await Promise.all([
                ApiV2.http.get('/api/metadata/tenants/current/users').then((r) => r.data),
                ApiV2.http.get('api/metadata/tenant/current/usergroups').then((r) => r.data),
                ApiV2.http.get('api/metadata/tenant/current/delegatedusergroups').then((r) => r.data),
            ]);

            this.users = users;
            this.userGroups = userGroups;
            this.deletegatedUserGroups = delegatedUserGroups;
        }),
    },
    created() {
        this.getUsers();
    },
};
</script>
