
import { useTenantStore, useWaitStore, storeToRefs, useFilterStore, mapStores } from '@/stores';
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    IClickEvent,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    SpendUsageOptions,
    convertSpendOptionsToQuery,
    CostView,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import UsageFilterChips, {
    filterUsageParamsByFilterChips,
    IChipViewModel,
} from '@/components/Domain.Usage/UsageFilterChips.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref, watch } from 'vue';
import { getUsage, getForecast } from '@/lib/Api';
import { fromPlural } from '@/models/EntityType';
import { EntityType, PluralEntityType, IProductCategoryModel, ISpendUsageTableRecordsViewModel } from '@/models';
import { i18n } from '@/i18n';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
import SpendUsageUnitToggle from '@/components/Domain.Usage/SpendUsageUnitToggle.vue';
import { UnitOfDisplay } from '@/enums/UnitOfDisplay.enum';

export default defineComponent({
    components: {
        SpendUsageOptionsToolbarForm,
        UsageFilterChips,
        SpendUsageUnitToggle,
        SpendUsageTable,
        SpendUsageBarChart,
    },
    computed: {
        showUnitToggle() {
            return this.filterStore.$state.options.segregateBy == PluralEntityType.meters;
        },
    },
    props: { value: Object, loading: Boolean },
    setup(p) {
        const unitOfDisplay = ref(UnitOfDisplay.cost);
        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const filterStore = useFilterStore();
        filterStore.setFilter({ segregateBy: PluralEntityType.products });
        const spendOptions = ref<SpendUsageOptions>(filterStore.options);
        const newChartData = ref({ labels: [], datasets: [] });
        const maxDate = ref<string | null>(null);
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const filters = ref<IChipViewModel[]>([]);
        const searchSupportedTypes = ref<EntityType[]>([
            EntityType.product,
            EntityType.service,
            EntityType.subscription,
            EntityType.tag,
            EntityType.region,
            EntityType.geography,
        ]);
        const segregationSupportedKeys = ref<PluralEntityType[]>([
            PluralEntityType.products,
            PluralEntityType.services,
            PluralEntityType.meters,
        ]);

        const loadTabUsage = async () =>
            await whilst('getProductUsage', async () => {
                const { productCategoryName } = p.value as IProductCategoryModel;
                const options: SpendUsageOptions = {
                    ...spendOptions.value,
                    productCategory: `"${productCategoryName}"`,
                };
                if (Array.isArray(options.segregateBy)) throw new Error('Multiple segregations unsupported!');
                const segregateBy = options.segregateBy;
                let params = convertSpendOptionsToQuery(options);
                params = filterUsageParamsByFilterChips(filters.value, params);
                if (!params) return;
                const [usageResponse, projectionsResponse] = await Promise.all([
                    getUsage(segregateBy, params),
                    getForecast(params, options.dateRangeKey, options.forecastRangeKey),
                ]);
                const usageDataset = convertUsageResponseToDatasets(usageResponse, { reportType: UsageReportTypes.charges });
                const forecastDataset = convertProjectionsResponseToDataset(projectionsResponse);
                newChartData.value = {
                    labels: [...forecastDataset.labels, ...usageDataset.labels],
                    datasets: [...forecastDataset.datasets, ...usageDataset.datasets],
                };
                usageTableItems.value = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregateBy,
                    usageResponse,
                    options.costView
                );
                maxDate.value = projectionsResponse?.maxUsageDate || params.toDate
            });

        const handleDatasetClicked = ({ datasetGroupId, isOther, name }: IClickEvent) => {
            if (isOther) {
                spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
            } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
                toastError(i18n.t('shared.meterInformationNotSupported'));
            } else {
                filters.value.push({ id: datasetGroupId, name, type: fromPlural(spendOptions.value.segregateBy) as EntityType });
            }
        };

        watch(
            () => [p.value, spendOptions.value, filters.value],
            () => {
                if (!p.value || !spendOptions.value || is('getProductUsage')) return;
                filterStore.setFilter(spendOptions.value);
                loadTabUsage();
            },
            { immediate: true, deep: true }
        );

        return {
            spendOptions,
            newChartData,
            usageTableItems,
            filters,
            searchSupportedTypes,
            segregationSupportedKeys,
            loadTabUsage,
            handleDatasetClicked,
            currencyCode,
            wait: { is },
            unitOfDisplay,
            filterStore,
            maxDate
        };
    },
});
