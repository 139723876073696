<template>
    <form-card
        :title="$t('setPermissionsCard.title')"
        :subtitle="$t('setPermissionsCard.subtitle')"
        :loading="loading"
        v-model="valid"
    >
        <v-select :disabled="disabled && disabled.groupId" :items="groups" label="Group" v-model="groupId" item-text="name" item-value="id"></v-select>
        <v-list>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canRead" disabled></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setPermissionsCard.canRead.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setPermissionsCard.canRead.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canContribute"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setPermissionsCard.canContribute.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setPermissionsCard.canContribute.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canAdminister"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setPermissionsCard.canAdminister.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('setPermissionsCard.canAdminister.subtitle') }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-action>
                    <v-switch v-model="canManageBilling"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('setPermissionsCard.canManageBilling.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                        $t('setPermissionsCard.canManageBilling.subtitle')
                    }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template #actions="{ formId }">
            <slot
                name="actions"
                :form="formData"
                :formId="formId"
                :isValid="valid"
                :loading="loading"
                :clear="reset"
            ></slot>
        </template>
    </form-card>
</template>
<i18n>
{
    "en": {
        "setPermissionsCard.title": "Set Permissions",
        "setPermissionsCard.subtitle": "Indicate the level of permissions this entity should have over this tenant.",
        "setPermissionsCard.canRead.title": "Reader",
        "setPermissionsCard.canRead.subtitle": "Can read all non-administrative data in this tenant.",
        "setPermissionsCard.canContribute.title": "Contributor",
        "setPermissionsCard.canContribute.subtitle": "Can perform non-administrative write functions like setting spend limits and adding annotations.",
        "setPermissionsCard.canAdminister.title": "Administrator",
        "setPermissionsCard.canAdminister.subtitle": "Can read and write administrative data, such as adding users to a tenant and creating new cloud connections.",
        "setPermissionsCard.canManageBilling.title": "Billing Administrator",
        "setPermissionsCard.canManageBilling.subtitle": "Can manage billing functions of the tenant."
    }
}
</i18n>

<script>
export default {
    props: ['loading', 'value', 'groups', 'disabled'],
    data() {
        return {
            valid: true,
            groupId: '',
            canRead: true,
            canAdminister: false,
            canContribute: true,
            canManageBilling: false,
            isEditMode: false,
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.reset();
                } else {
                    this.isEditMode = true;
                    this.groupId = val.groupId;
                    if (val.permissions) {
                        this.canRead = val.permissions.includes('CanRead');
                        this.canAdminister = val.permissions.includes('CanAdminister');
                        this.canContribute = val.permissions.includes('CanContribute');
                        this.canManageBilling = val.permissions.includes('CanManageBilling');
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        formData() {
            const permissions = [];
            if (this.canRead) permissions.push('CanRead');
            if (this.canAdminister) permissions.push('CanAdminister');
            if (this.canContribute) permissions.push('CanContribute');
            if (this.canManageBilling) permissions.push('CanManageBilling');
            return {
                groupId: this.groupId,
                permissions,
            };
        },
    },
    methods: {
        reset() {
            this.isEditMode = false;
            this.canRead = true;
            this.canContribute = true;
            this.canAdminister = false;
            this.canManageBilling = false;
        },
    },
};
</script>
