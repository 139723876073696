
import { defineComponent, ref, computed } from 'vue';
import EntitySearchAutocomplete, { ISearchEntityViewModel } from '@/components/Common/EntitySearchAutocomplete.vue';
import { useTenantStore, storeToRefs } from '@/stores';
import { disableDimension, enableDimension, pinDimension, unpinDimension } from '@/lib/Api';

export default defineComponent({
    components: { EntitySearchAutocomplete },
    setup() {
        const newEntities = ref<ISearchEntityViewModel[]>([]);
        const tenantStore = useTenantStore();
        const { dimensions } = storeToRefs(tenantStore);

        const configuredDimensions = computed(() => {
            if (!dimensions.value) return [];
            return Object.values(dimensions.value);
        });
        const tryDemote = async (dim: string) => {
            if (window.confirm(`Are you sure you want to demote ${dim}`)) {
                await disableDimension(dim);
                await tenantStore.loadTenant();
            }
        };
        const tryUnpin = async (dim: string) => {
            if (window.confirm(`Are you sure you want to unpin ${dim}`)) {
                await unpinDimension(dim);
                await tenantStore.loadTenant();
            }
        };
        const tryPromoteTagKeys = async () => {
            for (const key of newEntities.value) {
                await enableDimension(key.Id);
            }
            newEntities.value = [];
            await tenantStore.loadTenant();
        };
        const tryPin = async (dim: string) => {
            await pinDimension(dim);
            await tenantStore.loadTenant();
        };
        return { newEntities, dimensions, configuredDimensions, tryDemote, tryPromoteTagKeys, tryUnpin, tryPin };
    },
});
