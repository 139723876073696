import auth0Client from "./auth0";
import msalClient from "./msal";
import Config from '@/lib/Config';
import Vue from 'vue';
import { PublicClientApplication } from '@azure/msal-browser';
import { Auth0Client } from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = s => window.history.replaceState({}, document.title, window.location.pathname);

const client =
    Config.isFeatureEnabled('UseMsalAuthentication') ? msalClient : auth0Client;

export interface IAuthVue {
    getTokenSilently(): Promise<string>;
    loginWithRedirect(o: any): Promise<void>;
    logoutWithRedirect(): Promise<void>;
}

export interface IAuthVueData {
    loading: boolean;
    isAuthenticated: boolean;
    user: Record<any, any>;
    client: null | Auth0Client | PublicClientApplication;
    error: null | Error;
}

let instance: Vue & IAuthVue & IAuthVueData | null = null;

export const getInstance = () => instance;

export const useAuth = ({
        onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
    if (instance) return instance;

    instance = new Vue({
        data(): IAuthVueData {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                client: null,
                error: null
            };
        },
        methods: {
            getTokenSilently: () => client.getTokenSilently(),
            loginWithRedirect: (options: any) => client.loginWithRedirect(options),
            logoutWithRedirect: () => client.logoutWithRedirect()
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            try {
                this.client = client;
                await client.initialize({ onRedirectCallback });
            } catch (error) {
                this.error = error;
            } finally {
                this.isAuthenticated = await client.isAuthenticated();
                this.user = await client.getUser();
                this.loading = false;
            }
        }
    });
    
    return instance;
}

export const AuthPlugin = {
    install(vue, options) {
        vue.prototype.$auth = useAuth(options);
    }
};