import Vue from "vue";
import Vuetify from "vuetify/lib";
import Config from "@/lib/Config";

Vue.use(Vuetify);

const theme = { ...Config.get().DEFAULT_THEME };
const customThemes = theme.customThemes || null;
delete theme.customThemes;
const darkThemePreference = localStorage.getItem("dark_theme");
if (darkThemePreference) {
    theme.dark = darkThemePreference === "true";
} else if (window.matchMedia) {
    try {
        theme.dark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    } finally {
        // ignore browser compatibility issues
    }
}

if (customThemes) {
    for (const key in customThemes) {
        document.documentElement.style.setProperty(
            `--${key}`,
            customThemes[key],
        );
    }
}

export default new Vuetify({
    theme,
    icons: {
        iconfont: "md",
    },
});
