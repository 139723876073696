
import { defineComponent, computed, ref, watch } from "vue";

export interface IItemViewModel {
  value: string;
}

export default defineComponent({
  props: {
    displayText: String,
    value: Array,
    label: String,
    innerLabel: String,
    min: Number,
    max: Number,
    title: String,
    subtitle: String,
    rules: Array,
    innerRules: Array,
  },
  setup(p, { emit }) {
    const readText = computed(() => {
      return p.value?.length ? p.value.join(",") : "";
    });
    const items = ref<IItemViewModel[]>([]);
    const isOpen = ref(false);
    const isValid = ref(false);
    const open = () => {
      if (isOpen.value) return;
      isOpen.value = true;
    };
    const close = () => {
      if (!isOpen.value) return;
      isOpen.value = false;
    };
    const deleteItem = (i: number) => {
      items.value.splice(i, 1);
      items.value = [...items.value];
    };
    const addItem = () => {
      items.value.push({ value: "" });
    };
    const submit = () => {
      emit(
        "input",
        items.value.map((i) => i.value)
      );
    };
    const additionalIsValid = computed(() => {
      if (p.min && items.value.length < p.min) return false;
      if (p.max && items.value.length > p.max) return false;
      return true;
    });
    watch(
      () => p.value,
      (v) => {
        if (!v || !v.length) items.value = [{ value: "" }];
        else items.value = v.map((value: string) => ({ value }));
      },
      { immediate: true, deep: true }
    );
    return {
      readText,
      isOpen,
      isValid,
      items,
      additionalIsValid,
      addItem,
      deleteItem,
      submit,
      open,
      close,
    };
  },
});
