import DateKey from "@/lib/DateKey";
import EntityType, { PluralEntityType } from "./EntityType";
import Granularity from "./Granularity";
import { ISavedReportResultParams } from "./ISavedReportResult";
import ISpendParameters from "./ISpendParameters";
import { OmitExcept, appendValueToCommaString } from "@/lib";
import { SpendUsageOptions } from "@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue";

export const generateSpendUsageOptionsFromReport = (r: ISavedReportResultParams): OmitExcept<SpendUsageOptions, 'costView'> => {
    const dateKey = new DateKey(r.dateRangeKey);
    const fromDate = dateKey.isCustom ? new Date(r.fromDate) : null;
    const toDate = dateKey.isCustom ? new Date(r.toDate) : null;
    const dates = dateKey.getEffectiveDateRange(fromDate, toDate);

    const p: OmitExcept<SpendUsageOptions, 'costView'> = {
        granularity: r.granularity === Granularity.daily || r.granularity === Granularity.monthly
            ? r.granularity
            : Granularity.daily,
        toDate: dates[1],
        fromDate: dates[0],
        topXResults: r.topXResults,
        dateRangeKey: r.dateRangeKey,
        savedViewId: r.savedViewId,
        forecastRangeKey: r.granularity === Granularity.daily ? '7d' : '3m'
    };

    const subscriptionIds: string[] = [],
        serviceIds: string[] = [],
        cloudAccountIds: string[] = [],
        productIds: string[] = [],
        regionNames: string[] = [],
        geographyNames: string[] = [];
    let tag: string | null = null;
    let cloudProvider: string | null = null;
    let savedViewId: string | null = null;
    let productCategory: string | null = null;

    r.filters.forEach((i) => {
        if (i.type === EntityType.subscription) {
            subscriptionIds.push(i.id);
        } else if (i.type === EntityType.service) {
            serviceIds.push(i.id);
        } else if (i.type === EntityType.cloudAccount) {
            cloudAccountIds.push(i.id);
        } else if (i.type === EntityType.product) {
            productIds.push(i.id);
        } else if (i.type === EntityType.region) {
            regionNames.push(i.name);
        } else if (i.type === EntityType.geography) {
            geographyNames.push(i.name);
        } else if (i.type === EntityType.tag) {
            tag = i.id;
        } else if (i.type === EntityType.cloudProvider) {
            cloudProvider = i.name;
        } else if (i.type === EntityType.customView) {
            savedViewId = i.id;
        } else if (i.type === EntityType.productCategory) {
            productCategory = i.name;
        }
    });

    if (productIds.length)
        p.productId = productIds[0];
    if (cloudAccountIds.length)
        p.cloudAccountId = cloudAccountIds[0];
    if (serviceIds.length)
        p.serviceId = serviceIds[0];
    if (subscriptionIds.length)
        p.subscriptionIds = subscriptionIds;
    if (serviceIds.length)
        p.serviceId = serviceIds[0];
    if (regionNames.length)
        p.regionName = regionNames[0];
    if (geographyNames.length)
        p.geographyName = geographyNames[0];
    if (tag)
        p.tagKeyValue = tag;
    if (cloudProvider)
        p.providerType = cloudProvider;
    if (savedViewId)
        p.savedViewId = savedViewId;
    if (productCategory)
        p.productCategory = productCategory;

    const segregatedBy = (r.segregateBy || '').split(',');
    if (segregatedBy.length > 1) {
        p.segregateBy = segregatedBy as PluralEntityType[];
    } else if (segregatedBy.length) {
        p.segregateBy = segregatedBy[0] as PluralEntityType;
    }

    return p;
}

export const generateSpendParametersFromReport = (r: ISavedReportResultParams): OmitExcept<ISpendParameters, 'costView'> => {

    const dateKey = new DateKey(r.dateRangeKey);
    const fromDate = dateKey.isCustom ? new Date(r.fromDate) : null;
    const toDate = dateKey.isCustom ? new Date(r.toDate) : null;
    const dates = dateKey.getEffectiveDateRange(fromDate, toDate);

    const p: OmitExcept<ISpendParameters, 'costView'> = {
        granularity: r.granularity === Granularity.daily || r.granularity === Granularity.monthly
            ? r.granularity
            : Granularity.daily,
        toDate: dates[1].toISOString(),
        fromDate: dates[0].toISOString(),
        topXResults: r.topXResults,
        topBy: '',
        cloudAccountIds: '',
        geographyNames: '',
        productCategories: '',
        productIds: '',
        providerType: '',
        regionNames: '',
        serviceIds: '',
        tagKeyValue: '',
        subscriptionIds: '',
        tagKeyValues: '',
        reservationId: '',
    };

    const subscriptionIds: string[] = [],
        serviceIds: string[] = [],
        cloudAccountIds: string[] = [],
        productIds: string[] = [],
        regionNames: string[] = [],
        geographyNames: string[] = [];
    let tag: string | null = null;
    let cloudProvider: string | null = null;
    let savedViewId: string | null = null;

    r.filters.forEach((i) => {
        if (i.type === EntityType.subscription) {
            subscriptionIds.push(i.id);
        } else if (i.type === EntityType.service) {
            serviceIds.push(i.id);
        } else if (i.type === EntityType.cloudAccount) {
            cloudAccountIds.push(i.id);
        } else if (i.type === EntityType.product) {
            productIds.push(i.id);
        } else if (i.type === EntityType.region) {
            regionNames.push(i.name);
        } else if (i.type === EntityType.geography) {
            geographyNames.push(i.name);
        } else if (i.type === EntityType.tag) {
            tag = i.id;
        } else if (i.type === EntityType.cloudProvider) {
            cloudProvider = i.name;
        } else if (i.type === EntityType.customView) {
            savedViewId = i.id;
        }
    });

    p.productIds = appendValueToCommaString('', productIds);
    p.cloudAccountIds = appendValueToCommaString('', cloudAccountIds);
    p.serviceIds = appendValueToCommaString('', serviceIds);
    p.subscriptionIds = appendValueToCommaString('', subscriptionIds);
    p.regionNames = appendValueToCommaString('', regionNames);
    p.geographyNames = appendValueToCommaString('', geographyNames);
    p.tagKeyValue = tag;
    p.providerType = cloudProvider;
    p.savedViewId = savedViewId || r.savedViewId || null;

    return p;
};