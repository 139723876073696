import Config from "@/lib/Config";
const colorsOverride = Config.get().CHART_COLORS.filter(c => c !== '');

const hexToRgb = (hex: string) => {
    const normalized = hex.replace(/^#/, "");
    const bigint = parseInt(normalized, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
};

export const LEGACY_COLORS_HEX = colorsOverride && colorsOverride.length > 0 ? colorsOverride : [
    "#7cb5ec",
    "#434348",
    "#90ed7d",
    "#f7a35c",
    "#8085e9",
    "#f15c80",
    "#e4d354",
    "#2b908f",
    "#f45b5b",
    "#91e8e1",
];
export const LEGACY_COLORS_RGB = LEGACY_COLORS_HEX.map(hex => {
    const rgb = hexToRgb(hex);
    return [ rgb.r.toString(), rgb.g.toString(), rgb.b.toString() ]
});
