<template>
    <form-card :title="title" :subtitle="subtitle" :loading="loading" v-model="valid">
        <v-text-field
            v-model="entityName"
            :rules="[$rules.required()]"
            persistent-hint
            :label="$t('name')"
            required
        ></v-text-field>
        <v-textarea
            v-model="entityDescription"
            :rules="[$rules.required()]"
            persistent-hint
            :label="$t('description')"
            required
        ></v-textarea>
        <template #actions="{ formId, reset }">
            <slot
                :isValid="valid"
                :clear="reset"
                :formId="formId"
                :loading="loading"
                :form="{ name: entityName, description: entityDescription }"
            ></slot>
        </template>
    </form-card>
</template>

<script>
export default {
    props: {
        value: Object,
        loading: {
            default: false,
            type: Boolean,
        },
        title: {
            default: 'Update Name',
            type: String,
        },
        subtitle: {
            default: 'Please specify the new name you would like',
            type: String,
        },
    },
    data() {
        return {
            valid: true,
            entityName: '',
            entityDescription: ''
        };
    },
    watch: {
        value: {
            handler(val) {
                if (!val) return;
                this.entityName = val.name || '';
                this.entityDescription = val.description || '';
            },
            immediate: true
        },
    },
};
</script>