import { render, staticRenderFns } from "./AWSMarketplace.vue?vue&type=template&id=26b7c959&"
import script from "./AWSMarketplace.vue?vue&type=script&lang=ts&"
export * from "./AWSMarketplace.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports