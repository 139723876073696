import IMailingListGetPutModel from "./IMailingListGetPutModel";
import { ICustomViewSummaryResult } from './ICustomViewResult';
import IWebhookGetPutModel from "./IWebhookGetPutModel";

export interface IPolicyTemplateModel {
    id: number;
    name: string;
    description: string;
    category: string;
}

export interface IDimensionModel {
    id: number;
    label: string;
    index: number;
    tagKey: string;
}

export interface IDeltaSpendStrategyModel {
    serviceName: string;
    triggerMinDeltaPercent?: number;
    triggerMinDeltaAmount?: number;
    granularity: string;
    comparePeriod: string;
    comparePeriodQuantity: number;
    description: string;
    lineItemType: string;
}

export interface IAverageSpendStrategyModel {
    averageOverDays?: number;
    triggerMinDeltaPercent?: number;
    triggerMinDeltaAmount?: number;
    granularity: string;
    comparePeriod: string;
    comparePeriodQuantity: number;
    description: string;
    lineItemType: string;
}

export enum CostView {
    usageOnly = 'UsageOnly',
    usagePlusFees = 'UsagePlusFees',
    feesOnly = 'FeesOnly'
}

export interface INewSpendStrategyModel {
    triggerMinNewAmount?: number;
    compareType: string;
    granularity: string;
    comparePeriod: string;
    comparePeriodQuantity: number;
    description: string;
    lineItemType: string;
}

export interface INewAccountStrategyModel {
    triggerMinNewAmount?: number;
    comparePeriod: string;
    comparePeriodQuantity: number;
    description: string;
    lineItemType: string;
}

export interface IRegionalSpendStrategyModel {
    triggerMinUsageAmount: number;
    triggerMinRegionCount: number;
    description: string;
    comparePeriod: string;
    comparePeriodQuantity: number;
    lineItemType: string;
}

export interface IPolicyScheduleModel {
    id: number;
    frequency: string;
    frequencyQuantity: number;
    day?: number;
    nextScheduled: Date;
    emailNotifyList: string;
    description: string;
}

export interface IPolicyResultModel {
    id: number;
    cloudAccountId?: string;
    cloudAccountName: string;
    ranAt: Date;
    resultCount: number;
    scheduleDescription: string;
    dimensionLabel: string;
    resultPages: { [index: number]: number };
}

export interface IPolicyResultWithPolicyModel extends IPolicyResultModel {
    policyId: number;
    policyName: string;
    severity: string;
}

export default interface IPolicyModel {
    id: number;
    name: string;
    dimension: IDimensionModel;
    severity: string;
    description: string;
    strategySummary: string;
    lineItemType: string;
    deltaSpendStrategy?: IDeltaSpendStrategyModel;
    newSpendStrategy?: INewSpendStrategyModel;    
    regionalSpendStrategy?: IRegionalSpendStrategyModel;
    newAccountStrategy?: INewAccountStrategyModel;
    averageSpendStrategy?: IAverageSpendStrategyModel;
    schedule?: IPolicyScheduleModel;
    mailingList?: IMailingListGetPutModel;
    customView?: Partial<ICustomViewSummaryResult> | null;
    webhook?: Partial<IWebhookGetPutModel> | null;
    createdDate: Date;
    updatedDate: Date;
    lastRun?: Date;
    lastRunRecordCount: number;
}

export interface IPolicySummaryModel {
    totalCritical: number;
    totalWarning: number;
    totalPolicies: number;
    totalResultCount: number;
}