import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18nRules = {
    en: {
        "validation.required": "Required",
        "validation.invalid": "Invalid",
        "validation.maxLength": "Must be {num} or less characters",
        "validation.minLength": "Must be {num} or more characters",
        "validation.email": "Must be a valid email",
        "validation.time": "Must be a valid time (hh:mm)",
        "validation.multipleOf": "Increments of {num}",
        "validation.max": "Must be {num} or less",
        "validation.min": "Must be {num} or more",
        "validation.doesNotMatch": "Does not match",
        "validation.containsDigit": "Must contain a digit",
        "validation.containsLower": "Must contain a lowercase character",
        "validation.containsUpper": "Must contain an uppercase character",
        "validation.containsSpecialChar":
            "Must contain a special character like -+_!@#$%^&*.,?",
        "validation.regex": "Does not meet all requirements for this input",
    },
};

const i8nShared = {
    en: {
        "shared.meterInformationNotSupported":
            "Drill down into Meter usage is not currently supported.",
    },
};

export const i18n = new VueI18n({
    locale: "en",
    silentTranslationWarn: true,
    messages: {
        en: {
            ...i18nRules.en,
            ...i8nShared.en,
            name: "Name",
            useTenantCurrencies: "Apply tenant FX overrides",
            "useTenantCurrencies.tooltip":
                "In lieu of any overrides tenants will apply a core set of static FX rates updated monthly. To update the overrides navigate to the FX rates tab in the settings page.",
            edit: "Edit",
            type: "Type",
            none: "None",
            description: "Description",
            back: "Back",
            permissions: "Permissions",
            delete: "Delete",
            view: "View",
            disable: "Disable",
            close: "Close",
            create: "Create",
            connected: "Connected",
            "cloud accounts": "Cloud Connections",
            "azure accounts": "Azure Accounts",
            "amazon accounts": "Amazon Accounts",
            "alibaba accounts": "Alibaba Accounts",
            "usage connection": "Usage Connection",
            "arm connection": "ARM Connection",
            "edit name": "Edit Name",
            "edit currencies": "Edit FX Rates",
            "contact us": "Contact Us",
            "all azure accounts": "All Azure accounts",
            "all amazon accounts": "All Amazon accounts",
            "all alibaba accounts": "All Alibaba accounts",
            "edit enhanced connection": "Edit Enhanced Connection",
            "edit usage connection": "Edit Usage Connection",
            "edit connection": "Edit Connection",
            "copy cloud account": "Copy Cloud Connection",
            expiry: "Expiry",
            email: "Email",
            administrator: "Administrator",
            role: "Role",
            actions: "Actions",
            login: "Login",
            password: "Password",
            "old password": "Old Password",
            "confirm password": "Confirm Password",
            company: "Company",
            "spendUsageChartCard.customDateTextFormat":
                "Usage by {segregatedBy} ({fromDate} - {toDate})",
            "spendUsageChartCard.fixedDateTextFormat":
                "{granularity} usage by {segregateBy} (Last {total} {granularityName})",
            "spendUsageChartCard.products.title": "Products",
            "spendUsageChartCard.productcategories.title": "Categories",
            "spendUsageChartCard.cloudproviders.title": "Cloud Providers",
            "spendUsageChartCard.services.title": "Services",
            "spendUsageChartCard.subscriptions.title": "Subscriptions",
            "spendUsageChartCard.meters.title": "Meters",
            "spendUsageChartCard.cloudaccounts.title": "Cloud Connections",
            "spendUsageChartCard.regions.title": "Regions",
            "spendUsageChartCard.tags.title": "Tags",
        },
        ja: {
            name: "名前",
            edit: "を編集",
            none: "なし",
            back: "戻る",
            delete: "削除する",
            create: "つくる",
            "cloud accounts": "統合",
            "azure accounts": "Azure統合",
            "amazon accounts": "Amazon統合",
            "usage connection": "消費接続",
            "arm connection": "ARM接続",
        },
    },
});
